import { Link, useLocation, useNavigate } from "react-router-dom"
import Button1 from "../common/GlobalButtonLayout/Button1"
import Button2 from "../common/GlobalButtonLayout/Button2"
import { useEffect, useRef, useState } from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { handleLoginSidebar, handleOpenSidebar } from "../../../store/mobileSideBarSlice";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";
import P20 from "../common/Typograpgy/P20";
import InputField from "../common/Input/InputField";
import { useForm } from 'react-hook-form';
import {  useApi } from "../../../api/api";
import OtpInput from "../common/Input/OtpInput";
import { handleAuthModalStatus, login, logout, profileDetail, signup } from "../../../store/authenticationSlice";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SignUpModal from "../SignUpModal/SignUpModal";
import ForgotPasswordModal from "../ForgotPasswordModal";
import ResetPasswordModal from "../ResetPasswordModal/ResetPasswordModal";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { playerOn } from "../../../store/trackSlice";
import { handleOpenStepper } from "../../../store/stepperSlice";
import { useBeforeunload } from 'react-beforeunload';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalSearchBar from "../common/GlobalSearchBar/GlobalSearchBar";
import { handlePlayerStatus } from "../../../store/playerSlice";
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog } from 'primereact/dialog'
import { clearAllNotifications, handleBellNotificationCommunityPayload, handleBellNotificationPayload, removeNotification } from "../../../store/notificationBellSlice";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Tooltip from '@mui/material/Tooltip';
import BackToPage from "../common/BacktoPage/BackToPage";
import { handleFollowerRequestList, handleFollowerUserList } from "../../../store/followerSlice";
import { handleFollowingRequestList, handleFollowingUserList } from "../../../store/followingSlice";
import globalToast from "../../../hooks/globalToast";




function HeaderComponent({isFilter}){
    const dispatch = useDispatch()
    const [nav,setNav] = useState(2)
    const location = useLocation();
    const navigate = useNavigate()
    const [isOtpSend, setIsOtpSend] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);
    const [email, setEmail] = useState(false);
    const [verifyOtp, setVerifyOtp] = useState(false);
    const [isMobileLoginSideBar, setIsMobileLoginSideBar] = useState(false);
    const [open, setOpen] = useState(false);
    const { handlePostRequest,handleGetRequest } = useApi();
    const [matchPassword, setMatchPassword] = useState()
    const [verifyPassword, setVerifyPassword] = useState()
    const [userDetailModal, setUserDetailModal] = useState(false)
    // const [authModal, setAuthModal] = useState()
    const [resetEmail, setResetEmail] = useState()
    const tokenStatus = useSelector((state) => state.auth.tokenStatus);
    const profileData = useSelector((state) => state.auth.profileData);
    const authModal = useSelector((state) => state.auth.authModalStatus);
    const [dateSave, setDateSave] = useState(null);
    const [formatDate,setFormatDate] = useState()
    const isDark = useSelector((state) => state.dark.isDarkValue);
    const notificationbell = useSelector((state) => state.bell.payloadBell);
    const notificationbellCommunity = useSelector((state) => state.bell.payloadCommunity);
    const searchValue = localStorage.getItem('searchValue');
    const [expanded, setExpanded] = useState(searchValue?true:false);
    const token = localStorage.getItem('fcmToken');


    // const readNotificationsCount = notificationbellCommunity?.filter(notification => !notification?.is_read)?.length;




const readNotificationsCount = Array.isArray(notificationbellCommunity)
  ? notificationbellCommunity.filter(notification => !notification?.is_read).length
  : 0;



    const [notificationCount, setNotificationCount] = useState(readNotificationsCount?readNotificationsCount:0);
    
    console.log("notificationbell", notificationCount);


    useEffect(()=>{
        setNotificationCount(readNotificationsCount)
    },[notificationbellCommunity])


    useEffect(()=>{
        const date = new Date(dateSave);
        const year = date.getFullYear();
        let month = date.getMonth() + 1; // Months are zero-based
        let day = date.getDate();
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        setFormatDate(`${year}-${month}-${day}`)
    },[dateSave])

    useEffect(()=>{
        dispatch(handleLoginSidebar(userDetailModal))
    },[userDetailModal])


    const [rememberMe, setRememberMe] = useState( JSON.parse(localStorage.getItem("rememberMe")!==null?JSON.parse(localStorage.getItem("rememberMe")) : true ));

    useEffect(()=>{
        localStorage.setItem('rememberMe', JSON.stringify(rememberMe))
    },[rememberMe])
    // const userData = useSelector((state) => state.auth.userData);
    const modalRef = useRef(null);
    // const profileData = useSelector((state) => state.auth.profileData);

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                // Click is outside of modal, close it
                setUserDetailModal(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


   

    useEffect(()=>{
        setIsOtpSend(false)
    },[tokenStatus])
   

   

    const handleMobileSidebar = () => {
        dispatch(handleOpenSidebar())
    }

    const handleOpen = (para) => { 
        // setAuthModal(para)
        setOpen(true)
        setIsMobileLoginSideBar(false)
        dispatch(handleAuthModalStatus(para))

    }
    const firstRenderRef = useRef(true);

    useEffect(()=>{
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }
        if(authModal!==""){
            setOpen(true)
            setIsMobileLoginSideBar(false)
        }
    },[authModal])


    useEffect(()=>{
        dispatch(handleLoginSidebar(isMobileLoginSideBar))
        if (isMobileLoginSideBar) {
            document.body.classList.add('no-scroll');
          } else {
            document.body.classList.remove('no-scroll');
          }
    },[isMobileLoginSideBar])
    
    const handleClose = () => setOpen(false);
    const { register, handleSubmit,watch, formState: { errors }, reset  } = useForm();
    const cpassword = watch("cpassword")
    const v_password = watch("v_password")
   
    
    useEffect(()=>{
        setMatchPassword(cpassword)
    },[cpassword])

    useEffect(()=>{
        setVerifyPassword(v_password)
    },[v_password])

    

    const onSubmit = async (data) => {
        console.log("data.Email",data.Email);
        if(matchPassword!==verifyPassword){
        // toast.error("Confirm password is incorrect");
        globalToast("Confirm password is incorrect",true)

            return;
      }

      setEmail(data.Email)

      const sendCodePayload = {
        v_email_id:data.Email,
      }

      const sendCodeForgotPassPayload = {
        action:"forgot_password",
        v_email_id:data.Email,
      }

      const signUpPayload = {
        v_email_id:data.Email,
        d_dob:formatDate,
        // d_dob:data.d_dob,
        e_gender:data.e_gender,
        cpassword:data.cpassword,
        v_password:data.v_password
      }

      const loginPayload = {
        v_email_id:data.Email,
        v_password:data.loginPassword,
        v_device_token: 'test',
        e_device_type: 'web',
        // i_remember_me:rememberMe===false?1:0
        i_remember_me:rememberMe

      }

      const resetPassPayload = {
        v_email_id: resetEmail,
        v_password:data.cpassword,
        v_confirm_password: data.v_password
    }

      if(!verifyOtp  && authModal==="signUp"){
        // const sendOtpRes = await handlePostRequest(`${'users/send-code'}`,sendCodePayload,true,true)
         setIsOtpSend(true)
         setChangeEmail(false)
      }

      if(verifyOtp && authModal==="signUp"){
        const signUpRes = await handlePostRequest(`${'users/signup'}`,signUpPayload,true,true)
        console.log("signUpRes",signUpRes?.data?.isUserExists);
        localStorage.setItem('AuthToken', JSON.stringify(signUpRes?.data?.api_token))
        dispatch(signup(signUpRes?.data))
        const profileDataReq = await handleGetRequest("profile-detail",false, true)
        dispatch(profileDetail(profileDataReq?.data))
        reset()
        handleClose()
        setIsOtpSend(false)
        setChangeEmail(false)
        setEmail(false)
        setVerifyOtp(false)
        setMatchPassword()
        setVerifyPassword()
        setUserDetailModal()
        // setAuthModal()
        dispatch(handleAuthModalStatus(""))
        setResetEmail()
        dispatch(handleOpenStepper(true))
      }

      if(authModal==="login"){
        const token = localStorage.getItem('fcmToken');
        const loginRes = await handlePostRequest(`${'users/login'}`,loginPayload,true,true)
        console.log("loginRes",loginRes?.data);
        if(loginRes?.data?.api_token){
            // localStorage.setItem('AuthToken', JSON.stringify(loginRes?.data?.api_token))
            // dispatch(login(loginRes?.data))
            if(rememberMe){
                localStorage.setItem('AuthToken', JSON.stringify(loginRes?.data?.api_token))
                localStorage.setItem('volume', JSON.stringify(0.25))
                dispatch(login(loginRes?.data))
            }else{
                localStorage.removeItem('AuthToken')
                sessionStorage.setItem('AuthToken', JSON.stringify(loginRes?.data?.api_token))
                localStorage.setItem('volume', JSON.stringify(0.25))
                dispatch(login(loginRes?.data))
            }

            const profileDataReq = await handleGetRequest("profile-detail",false, true)
            console.log("profileDataReq",profileDataReq);
            dispatch(profileDetail(profileDataReq?.data))

        
            reset()
            handleClose()
            setIsOtpSend(false)
            setChangeEmail(false)
            setEmail(false)
            setVerifyOtp(false)
            setMatchPassword()
            setVerifyPassword()
            setUserDetailModal()
            // setAuthModal()
            dispatch(handleAuthModalStatus(""))
            setResetEmail()
            if(!token || token==="null"){

                console.log("token not found");
                window.location.reload()
                return
            }
            const payload= {
                device_token:localStorage.getItem('fcmToken')
            }
            const fcmResponse = await handlePostRequest("saveDeviceToken", payload,false, true)
        }
      }

      if(authModal==="forgotPassModal"){
        // const emailExitRes = await handlePostRequest(`${'users/email-exists'}`,sendCodePayload,true,true)
        // console.log("emailExitRes",emailExitRes?.flag);
        // if(emailExitRes?.flag){
        //     const sendCodeForgotPassRes = await handlePostRequest(`${'users/send-code'}`,sendCodeForgotPassPayload,true,true)
        //     console.log("sendCodeForgotPassRes",sendCodeForgotPassRes?.flag);
        //     reset()
        //     // setAuthModal('forgotPassOtpModal')
        //     dispatch(handleAuthModalStatus('forgotPassOtpModal'))

        // }
        dispatch(handleAuthModalStatus('forgotPassOtpModal'))

      }

      if(authModal==="resetPasswordModal"){
        const sendCodeForgotPassRes = await handlePostRequest(`${'users/reset-password'}`,resetPassPayload,true,true)
        console.log("sendCodeForgotPassRes",sendCodeForgotPassRes?.flag);
        reset()
        handleClose()
        setIsOtpSend(false)
        setChangeEmail(false)
        setEmail(false)
        setVerifyOtp(false)
        setMatchPassword()
        setVerifyPassword()
        setUserDetailModal()
        // setAuthModal()
        dispatch(handleAuthModalStatus(''))
        setResetEmail()
      }
    };

   useEffect(() => {
     const saveDeviceToken = async () => {
       const payload = {
         device_token: localStorage.getItem('fcmToken'),
       };
       if(token && token!=="null"){
           const fcmResponse = await handlePostRequest('saveDeviceToken', payload, false, true);
       }
     };
   
     saveDeviceToken();
   }, [token]);

    const [isCreateModal, setIsCreateModal] = useState(false)
    // 
    const signOut = async() => {
        const dToken = localStorage.getItem('fcmToken')
        const isDark = localStorage.getItem('isDark')
        const sendCodeForgotPassRes = await handleGetRequest(`users/logout`,true,false)
        reset()
        handleClose()
        setIsOtpSend(false)
        setChangeEmail(false)
        setEmail(false)
        setVerifyOtp(false)
        setMatchPassword()
        setVerifyPassword()
        setUserDetailModal()
        // setAuthModal()
        dispatch(handleAuthModalStatus(''))
        setResetEmail()
        dispatch(logout())
        dispatch(handlePlayerStatus(false))
        dispatch(clearAllNotifications())
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('fcmToken', dToken);
        localStorage.setItem('isDark', isDark);
    }

    const handleForgotPassModal = () => {
        reset()
        handleClose()
        setIsOtpSend(false)
        setChangeEmail(false)
        setEmail(false)
        setVerifyOtp(false)
        setMatchPassword()
        setVerifyPassword()
        setUserDetailModal()
        // setAuthModal()
        dispatch(handleAuthModalStatus(''))

        setResetEmail()
    }

    const closeModal = () => {
        reset()
        setIsOtpSend(false)
        setChangeEmail(false)
        setEmail(false)
        setVerifyOtp(false)
        setMatchPassword()
        setVerifyPassword()
        setUserDetailModal()
        // setAuthModal()
        dispatch(handleAuthModalStatus(''))

        setResetEmail()
        handleClose()
    }

    const [previousLocation, setPreviousLocation] = useState(null);

    useEffect(() => {
        if(previousLocation!=="/"){
            setPreviousLocation(location.pathname);
        }
    }, [location]);

    const [popupWindow,setPopupWindow] = useState()


    const connectSocialPlatform = async(platforma) => {
        console.log("connectPlatform",platforma);
        localStorage.setItem('socialSignInPlatform', JSON.stringify(platforma));

        // const options = "width=500,height=500 "
        const width = 500;
        const height = 500;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
      
        const options = `width=${width},height=${height},left=${left},top=${top}`;
        const connectPlatformRes = await handleGetRequest(`users/${platforma}/oauth`, false, true);
        const redirectUrlRes = connectPlatformRes?.data?.url;
        console.log("redirectUrlRes", redirectUrlRes);
        setPopupWindow(window.open(redirectUrlRes,"_blank",options))
        };

        useBeforeunload(() => {
            if (!rememberMe) localStorage.clear();
        });

        
      useEffect(() => {
        const handlePostMessage = async (event) => {
          console.log("handlePostMessage");
          console.log("handlePostMessageEvent", event);
          console.log("handlePostMessageEventData", event.data);
          console.log("handlePostMessageEventData", event.origin);
          const platform = JSON.parse(localStorage.getItem('socialSignInPlatform'));
          
          const {state} = event.data 
          const {code} = event.data 
          
          console.log("handlePostMessageEventDatacallback",state);
          const platformsPayload = {
            state:state,
            code:code
          }
      
          if (event.origin === "https://curioushit-frontend.nevinainfotech.in" && platform) {
            // Assuming handlePostRequest is defined elsewhere and takes appropriate arguments
            const callbackRes = await handlePostRequest(`users/${platform}/callback`,  platformsPayload, false,true);
            console.log("callbackRes", callbackRes);
            if(callbackRes?.flag){
                localStorage.setItem('AuthToken', JSON.stringify(callbackRes?.data?.api_token))
                dispatch(login(callbackRes?.data))
                const profileDataReq = await handleGetRequest("profile-detail",false, true)
                dispatch(profileDetail(profileDataReq?.data))
                localStorage.setItem('volume', JSON.stringify(0.25))
                 reset()
                handleClose()
                setIsOtpSend(false)
                setChangeEmail(false)
                setEmail(false)
                setVerifyOtp(false)
                setMatchPassword()
                setVerifyPassword()
                setUserDetailModal()
                // setAuthModal()
                dispatch(handleAuthModalStatus(''))
                setResetEmail()
                if(!callbackRes?.isUserExists){
                dispatch(handleOpenStepper(true))
                }
            }
          }
        };
      
        window.addEventListener("message", handlePostMessage, false);
      
        return () => {
          window.removeEventListener("message", handlePostMessage);
        };
      }, []); // Empty dependency array to run effect only once

      console.log("passError",authModal);

      const [isChecked, setIsChecked] = useState(false);

      const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };

      const [notificationPopUp,setNotificationPopUp] = useState(false)
      const [notificationPopUpMobile,setNotificationPopUpMobile] = useState(false)
      const modalRefs = useRef(null);
      const bellRefs = useRef(null);

      const handleClickOutside = (event) => {
        if (modalRefs.current && !modalRefs.current.contains(event.target) && bellRefs.current && !bellRefs.current.contains(event.target)  ) {
            setNotificationPopUp(false);
        }
    };

    const handleNotiPopUpMobile = () => {
        setNotificationPopUpMobile(pre=>!pre)
        handleLogin()
        const newUrl = `${location.pathname}?${Date.now()}`;
        window.history.pushState({}, '', newUrl);
    } 


    useEffect(() => {
        const handlePopState = (event) => {
       //    callback(event);
          setNotificationPopUpMobile(false)
          
        };
    
        window.addEventListener('popstate', handlePopState);
    
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
      }, [])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(()=>{
        if(notificationPopUp || notificationPopUpMobile){
            dispatch(handleLoginSidebar(true))
        }else{
            dispatch(handleLoginSidebar(false))
        }
    },[notificationPopUp,notificationPopUpMobile])

    const [animate, setAnimate] = useState(false);
    useEffect(()=>{
        // setNotificationPopUp(prev => !prev);
        setAnimate(true);
        setTimeout(() => setAnimate(false), 500); // Reset the animation state after 0.5s
    },[notificationbell])


    useEffect(()=>{
        if(notificationPopUpMobile){
            setNotificationPopUpMobile(false)
            
        }
    },[location])


    console.log("mmauthModal",authModal,authModal==="login");
    const handleLogin = async() => {
        
        if(!tokenStatus){
            dispatch(handleAuthModalStatus("login"))
            setOpen(true)
            setIsMobileLoginSideBar(false)
            setExpanded(false)
        }
       
    }
const [profileNameCapitalize,setProfileNameCapitalize] = useState("")

    useEffect(()=>{
        setProfileNameCapitalize(profileData?.v_name.replace(/\b\w/g, (char) => char.toUpperCase()))
    },[profileData])

    //   const capitalizeWords = (str) => {
    //     setProfileNameCapitalize(str.replace(/\b\w/g, (char) => char.toUpperCase()))
    //   };

    const followUser = async(id,isRequested) => {

        console.log("isRequested",isRequested);
         
        if(isRequested){
            const unFollowAcceptRes = await handleGetRequest(`removeFollowing/${id}`,true,true)
            if(unFollowAcceptRes?.flag){
              const followingUserRes = await handleGetRequest(`followings`,false,true)
              console.log("followingUserRes",followingUserRes?.data);
              dispatch(handleFollowingUserList(followingUserRes?.data))
              const pendingfollowingUserRes = await handleGetRequest(`pendingFollowings`,false,true)
              console.log("followingUserRes",pendingfollowingUserRes?.data);
              dispatch(handleFollowingRequestList(pendingfollowingUserRes?.data))
              const notificationResponseCommunity = await handleGetRequest(`notifications/allNotification`, false, false);
              console.log("ResponseCommunity",notificationResponseCommunity);   
              if(notificationResponseCommunity?.flag){
                dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
              }
            }
        
        }else{
            const followUserRes = await handleGetRequest(`follow/${id}`,true,true)
        console.log("followUserRes",followUserRes);
        if(followUserRes?.flag){
          const followingUserRes = await handleGetRequest(`followings`,false,true)
          console.log("followingUserRes",followingUserRes?.data);
          dispatch(handleFollowingUserList(followingUserRes?.data))
          const notificationResponseCommunity = await handleGetRequest(`notifications/allNotification`, false, false);
          console.log("ResponseCommunity",notificationResponseCommunity);

          if(notificationResponseCommunity?.flag){
            dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
          }
            }
        }
        
      }
    const acceptRejectUser = async(id,condition) => {
        const payload={
          follower_id:id,
          action:condition
        }
        if(id==="accepted"){
          const unFollowAcceptRes = await handlePostRequest(`acceptReject`,payload,true,true)
          if(unFollowAcceptRes?.flag){
            const followerUserRequestRes = await handleGetRequest(`pendingFollowers`,false,true)
            console.log("followingUserRes",followerUserRequestRes?.data);
            dispatch(handleFollowerRequestList(followerUserRequestRes?.data))
            const followerUserRes = await handleGetRequest(`followers`,false,true)
            console.log("followerUserRes",followerUserRes?.data);
            dispatch(handleFollowerUserList(followerUserRes?.data))
            const notificationResponseCommunity = await handleGetRequest(`notifications/allNotification`, false, false);
            console.log("ResponseCommunity",notificationResponseCommunity);

            if(notificationResponseCommunity?.flag){
            dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
          }
          }
        }else{
          const unFollowAcceptRes = await handlePostRequest(`acceptReject`,payload,true,true)
          if(unFollowAcceptRes?.flag){
            const followerUserRequestRes = await handleGetRequest(`pendingFollowers`,false,true)
            console.log("followingUserRes",followerUserRequestRes?.data);
            dispatch(handleFollowerRequestList(followerUserRequestRes?.data))
            const followerUserRes = await handleGetRequest(`followers`,false,true)
            console.log("followerUserRes",followerUserRes?.data);
            dispatch(handleFollowerUserList(followerUserRes?.data))
            const notificationResponseCommunity = await handleGetRequest(`notifications/allNotification`, false, false);
            console.log("ResponseCommunity",notificationResponseCommunity);

            if(notificationResponseCommunity?.flag){
            dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
          }
          }
        }
      }

      const handleCommunityClick = async() => {
        setNotificationCount(0)
        const readAllResponse = await handleGetRequest(`notifications/readAll`,false,false) ;
        // console.log("notificationResponseCommunity",notificationResponseCommunity?.data);
        // if(notificationResponseCommunity?.flag){
        //   dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
        // }
        const notificationResponseCommunity = await handleGetRequest(`notifications/allNotification`, false, false);
        console.log("ResponseCommunity",notificationResponseCommunity);

        if(notificationResponseCommunity?.flag){
        dispatch(handleBellNotificationCommunityPayload(notificationResponseCommunity?.data?.data))
      }
      }
    

    return(
        <div className={`p-2 lg:w-[82%] dark:bg-[#0D0D0D] ${isFilter?"z-0 lg:z-10 dark:lg:z-[32]":"z-[21]"} right-0 top-0 fixed w-full  ml-auto bg-[#FAFAF8]`}>
            {isMobileLoginSideBar&&<div className="w-full h-full top-0 left-0 bg-black bg-opacity-30 fixed z-10" onClick={()=>setIsMobileLoginSideBar(false)}></div>}
          
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={`${isDark?"dark":""}`}
            >
               <div className={`absolute w-full  overflow-scroll lg:overflow-auto lg:block flex flex-col h-full lg:h-auto justify-center my-auto  lg:w-4/6 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-3xl dark:bg-[#151515] dark:!h-auto  ${ authModal==="signUp"?"":authModal==="login"?"":"dark:w-[90%] dark:lg:w-[40%]"}`}>
                {(authModal==="login" || authModal==="signUp" ) &&<div className="lg:flex-row flex flex-col  h-full justify-center  rounded-3xl lg:bg-[#FFF4DD]  relative dark:bg-[#151515]">
               
                    <div className=" w-[90%] max-h-[95%] lg:max-h-full lg:overflow-auto  overflow-scroll  lg:w-1/2  mx-auto   rounded-l-3xl  bg-white rounded-r-3xl text-center relative dark:bg-[#151515]">
                    <div className="absolute z-50 top-0 right-3 lg:hidden flex justify-end items-center my-2">
                            <CancelOutlinedIcon onClick={()=>closeModal()} className=" text-[#D8D8D8] cursor-pointer !w-7 !h-7" />
                        </div>
                        <div className="bg-white w-full  py-4 rounded-t-3xl dark:bg-[#151515]">
                            <img src="/logo/LogoWorld.svg" alt="" className="w-36 mx-auto"/>
                        </div>
                        <P20 className="text-center mb-3 dark:text-[#DDD1CE]">{authModal==="signUp"?"All your music in one place":"Login to get all your music in one place"}</P20>
                        <form onSubmit={handleSubmit(onSubmit)} className="text-center ">
                            {/* { authModal!=="signUp"   ? <InputField 
                                type="text" 
                                placeholder="Your Email" 
                                name="Email" 
                                register={register} 
                                errors={errors}
                                disable={isOtpSend  && !changeEmail?true:false}
                                className={isOtpSend && !changeEmail?"!bg-[#E8F0FE] !text-[#C1C1C1] !mr-auto ":"!mr-auto "}
                                pattern={{
                                    value:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Invalid Email Format"
                                  }}
                                required={true}
                            /> */}
                            { authModal!=="signUp"   ? 
                            <div>
                            <input
                            placeholder="Your Email"
                            {...register('Email', {
                              required: 'Email is required',
                              pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                              },
                            })}
                            type="email"
                            // required
                            // className={`w-[80%] mx-auto text-center border-[2px] border-white focus:border-[2px]  bg-[#FAFAF8] dark:!bg-[#000000] dark:!text-[#C1C1C1] rounded-[50px] text-[#312522] text-[16px] font-normal py-2 my-1 ${errors && errors['Email'] && (errors['Email'].type === 'required' || errors['Email'].type === 'pattern') ? "!border-[#D80027] focus:!border-[#D80027]" : "focus:!border-[#F1B942]"} ${isOtpSend && !changeEmail?"!bg-[#E8F0FE] dark:!bg-[#000000] dark:!text-[#C1C1C1]  !text-[#C1C1C1] !mr-auto ":"!mr-auto "} `}



                            className={`w-[80%] ${isDark?"loginSignUp":""} dark:!border-black mx-auto text-center border-[2px] border-white focus:border-[2px] bg-[#FAFAF8] dark:bg-black dark:text-[#C1C1C1] rounded-[50px] text-[#312522] text-[16px] font-normal py-2 my-1 ${errors && errors['Email'] && (errors['Email'].type === 'required' || errors['Email'].type === 'pattern')? "!border-[#D80027] focus:!border-[#D80027]" : "focus:!border-[#F1B942]"} ${isOtpSend &&!changeEmail? "!bg-[#E8F0FE] dark:!bg-black dark:!text-[#C1C1C1]!text-[#C1C1C1]!mr-auto" : "!mr-auto"}`}
                            
                          />
                          {console.log("errors['Email'].type",errors)}
                          {errors && errors['Email'] && errors['Email'].type === 'required' && (
                            <p className="text-red-500 text-xs lg:text-sm">Email is required</p>
                            )}
                        {errors && errors['Email'] && errors['Email'].type === 'pattern' && (
                            <p className="text-red-500 text-xs lg:text-sm">{"Please enter a valid email"}</p>
                        )}
                            </div>
                          
                            :
                            <div  className="">
                            <input
                            placeholder="Your Email" 
                            {...register('Email', {
                              required: 'Email is required',
                              pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                              },
                            })}
                            type="email"
                            required
                            className={`w-[80%] ${isDark?"loginSignUp":""} dark:!border-black mx-auto text-center border-[2px] border-white focus:border-[2px]  bg-[#FAFAF8] dark:!bg-[#000000] dark:!text-[#C1C1C1] rounded-[50px] text-[#312522] text-[16px] font-normal py-2 my-1 ${errors && errors['Email'] && (errors['Email'].type === 'required' || errors['Email'].type === 'pattern') ? "!border-[#D80027] focus:!border-[#D80027]" : "focus:!border-[#F1B942]"} ${isOtpSend && !changeEmail?"!bg-[#E8F0FE] !text-[#C1C1C1] !mr-auto ":"!mr-auto "} `}
                          />

                            {errors && errors['Email'] && errors['Email'].type === 'required' && (
                            <p className="text-red-500 text-xs lg:text-sm">Email is required</p>
                            )}

                            {errors && errors['Email'] && errors['Email'].type === 'pattern' && (
                                <p className="text-red-500 text-xs lg:text-sm">{"Please enter a valid email"}</p>
                            )}
                            </div>
                            }
                           
                       { authModal==="signUp" && <SignUpModal handleSubmit={handleSubmit} date={dateSave} setDate={setDateSave} onSubmit={onSubmit} verifyOtp={verifyOtp} register={register} errors={errors} cpassword={cpassword} isOtpSend={isOtpSend}/>}
                       
                       {authModal==="login" &&
                       <div>
                        <InputField 
                            type="password" 
                            placeholder="Password" 
                            name="loginPassword" 
                            register={register} 
                            errors={errors}
                            required={true}
                        />
                        {errors && errors['loginPassword'] && errors['loginPassword'].type === "required" && (
                        <p className="text-red-500 text-xs lg:text-sm">Password is required</p>
                        )}

                        {errors && errors['loginPassword'] && errors['loginPassword'].type === 'pattern' && (
                            <p className="text-red-500 text-xs lg:text-sm">{"Password must meet given criteria"}</p>
                        )}
                        <div className="flex justify-between items-center w-[80%] mx-auto my-2 text-[#312522] font-semibold text-base">
                            <div>
                            <label>
                            <input
                            type="checkbox"
                            name="i_remember_me"  
                            className="forgot-btn-text accent-[#F1B942] border-white"
                            checked={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                            />
                           <span className="mx-1 text-[#312522] font-semibold dark:text-[#DDD1CE] text-xs"> Remember me</span>
                          </label>
                            </div>
        

                            {/* <div className="cursor-pointer dark:text-[#DDD1CE] text-xs" onClick={()=>setAuthModal('forgotPassModal')}>
                                Forgot password?
                            </div> */}
                            <div className="cursor-pointer dark:text-[#DDD1CE] text-xs" onClick={()=>dispatch(handleAuthModalStatus('forgotPassModal'))}>
                                Forgot password?
                            </div>
                        </div>
                        <Button1 type="submit" className="lg:!w-[50%] !w-[80%] text-center  dark:hover:!text-[#FDD169] ">
                            Login    
                        </Button1>
                       </div>
                       }
                        </form>
                        
                        {/* {isOtpSend &&!changeEmail&& !verifyOtp && <OtpInput setAuthModal={setAuthModal} authModal="signUp" setVerifyOtp={setVerifyOtp} setIsOtpSend={setIsOtpSend} email={email} setChangeEmail={setChangeEmail} reset={reset} />} */}
                        {isOtpSend &&!changeEmail&& !verifyOtp && <OtpInput  authModal="signUp" setVerifyOtp={setVerifyOtp} setIsOtpSend={setIsOtpSend} email={email} setChangeEmail={setChangeEmail} reset={reset} />}

                        {!isOtpSend&&<div>
                        <P20 className="dark:text-[#DDD1CE] !text-base mt-2">Or connect with</P20>
                        <div className="flex justify-center items-center gap-x-3 my-3 text-white">
                            <div onClick={()=>connectSocialPlatform('spotify')}  className="bg-[#1ED760] w-16 h-16 flex justify-center items-center  rounded-full cursor-pointer">
                                <div className="flex justify-center items-center my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30" fill="none">
                                <path d="M16.0001 0C7.71499 0 0.941406 6.74719 0.941406 15C0.941406 23.2528 7.71499 30 16.0001 30C24.2852 30 31.0588 23.2528 31.0588 15C31.0588 6.74719 24.3576 0 16.0001 0ZM22.9271 21.675C22.6268 22.1241 22.0979 22.275 21.6471 21.9722C18.1083 19.8 13.6669 19.35 8.39263 20.5472C7.8684 20.7019 7.41664 20.3269 7.26417 19.8722C7.11735 19.35 7.49005 18.9009 7.94087 18.75C13.6669 17.475 18.6372 17.9972 22.5487 20.4C23.0786 20.625 23.1511 21.225 22.9261 21.675H22.9271ZM24.7351 17.5491C24.3576 18.0769 23.6809 18.3019 23.1511 17.925C19.0881 15.4491 12.9112 14.7028 8.17145 16.2009C7.56816 16.3472 6.88676 16.05 6.73994 15.4481C6.58841 14.8481 6.88676 14.1741 7.49005 14.0231C12.9902 12.3722 19.7638 13.2019 24.4311 16.0491C24.8866 16.2741 25.1125 17.025 24.7351 17.5481V17.5491ZM24.8875 13.35C20.064 10.4972 12.0095 10.2 7.41664 11.6259C6.66276 11.85 5.91171 11.4009 5.68583 10.7222C5.46089 9.975 5.91171 9.22312 6.58841 8.99813C11.9371 7.42219 20.7445 7.72406 26.319 11.0241C26.9957 11.4 27.2197 12.2991 26.8433 12.9731C26.4659 13.5009 25.5633 13.7241 24.8866 13.3491L24.8875 13.35Z" fill="white"/>
                                </svg>
                                </div>
                            </div>
                            <div onClick={()=>connectSocialPlatform('apple')} className="bg-[#4A5568]  w-16 h-16  flex justify-center items-center rounded-full cursor-pointer">
                                <div className="flex justify-center items-center my-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 27 30" fill="none">
                                <path d="M22.4918 15.8533C22.4776 13.3953 23.6577 11.54 26.0461 10.1737C24.7097 8.37206 22.6909 7.38081 20.0252 7.18657C17.5016 6.99904 14.7435 8.57299 13.7341 8.57299C12.6678 8.57299 10.2224 7.25355 8.30309 7.25355C4.33649 7.31383 0.121094 10.234 0.121094 16.1748C0.121094 17.9296 0.462306 19.7424 1.14473 21.6133C2.05463 24.0713 5.3388 30.0992 8.76515 29.9988C10.5565 29.9586 11.8218 28.7999 14.1535 28.7999C16.414 28.7999 17.5869 29.9988 19.5844 29.9988C23.0392 29.9519 26.0106 24.4732 26.8778 22.0085C22.243 19.9523 22.4918 15.9806 22.4918 15.8533ZM18.4684 4.85579C20.409 2.68576 20.2313 0.709951 20.1744 0C18.4613 0.093767 16.478 1.09841 15.3477 2.33748C14.1037 3.66361 13.3715 5.30454 13.5279 7.15309C15.3832 7.28704 17.0751 6.38955 18.4684 4.85579Z" fill="white"/>
                                </svg>
                                </div>
                            </div>
                        </div>
                        </div>}
                        {!isOtpSend&& <div className="flex justify-center  space-x-2 px-10 lg:px-14">
                        <input
                            type="checkbox"
                            id="newsletter"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            className="form-checkbox h-10 w-10 lg:h-5 lg:w-5 accent-[#F4AE45]"
                        />
                        <label htmlFor="newsletter" className="text-gray-700 text-xs text-left dark:text-white">
                            I do not wish to receive news, offers and promotions from curioushit company by Email or by Phone.
                        </label>
                        </div>}
                        {!isOtpSend&&<div className="text-[#A8A8A8] mt-4 text-[12px] font-normal w-[80%] mx-auto pb-2 mb-4">
                            <p className="my-3 leading-5">By continuing, you agree to CuriousHit's <span className="text-[#312522]"><Link to="/terms-conditions" onClick={()=>closeModal()}  className="text-[#312522] hover:text-[#F4AE45] dark:text-[#DDD1CE]">Terms of Service</Link></span> and acknowledge you've read our <Link to="/privacy" className="text-[#312522] hover:text-[#F4AE45] dark:text-[#DDD1CE]" onClick={()=>closeModal()}>Privacy Policy</Link></p>
                            {/* {!isOtpSend&& authModal==="signUp" &&<p className="my-2">Already On CuriousHit ? <span className="text-[#312522] font-bold cursor-pointer hover:text-[#F4AE45] dark:text-[#DDD1CE]" onClick={()=>setAuthModal("login") }>Log in</span></p>}
                            { authModal==="login" &&<p className="my-2">Not on CuriousHit yet?  <span className="text-[#312522] font-bold cursor-pointer hover:text-[#F4AE45] dark:text-[#DDD1CE]" onClick={()=>setAuthModal("signUp")}>Sign up</span></p>} */}

                            {!isOtpSend&& authModal==="signUp" &&<p className="my-2">Already On CuriousHit ? <span className="text-[#312522] font-bold cursor-pointer hover:text-[#F4AE45] dark:text-[#DDD1CE]" onClick={()=>dispatch(handleAuthModalStatus("login")) }>Log in</span></p>}
                            { authModal==="login" &&<p className="my-2">Not on CuriousHit yet?  <span className="text-[#312522] font-bold cursor-pointer hover:text-[#F4AE45] dark:text-[#DDD1CE]" onClick={()=>dispatch(handleAuthModalStatus("signUp"))}>Sign up</span></p>}
                            {<Link to="/pro" onClick={()=> closeModal()} className="text-[#312522] cursor-pointer dark:text-[#DDD1CE]">Are you a music professional?</Link>}
                        </div>}
                    </div>
                    <div className="lg:w-1/2 !hidden rounded-r-3xl px-4 lg:!flex justify-center items-center  bg-[#FFF4DD] dark:bg-[#3B2900] relative">
                        <div className="absolute top-0 right-3 lg:flex justify-end items-center my-2 hidden ">
                            <CancelOutlinedIcon onClick={()=>closeModal()} className=" text-[#D8D8D8] cursor-pointer !w-7 !h-7" />
                        </div>
                        <img src="/images/Login/asdsd.png" alt="" className="" />
                    </div>
                </div>}

                { authModal==="forgotPassModal" && 
                <div className="h-full flex flex-col justify-center px-2">
                    <ForgotPasswordModal handleForgotPassModal={handleForgotPassModal} handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} errors={errors}/>
                </div>
                }

                { authModal==="forgotPassOtpModal" && 
                <div className="h-full flex flex-col justify-center px-2">

                <div className="lg:w-[65%] dark:lg:w-full min-h-52 rounded-xl  bg-white mx-auto flex justify-center items-center flex-col text-center pb-3 pt-7 px-4 dark:bg-[#151515]">
                    <h2 className="text-black text-sm lg:text-2xl my-3 dark:text-white">We have sent you an code to your registered Email ID to reset your password.</h2>
                    {/* <OtpInput authModal={authModal} setAuthModal={setAuthModal} setVerifyOtp={setVerifyOtp} setIsOtpSend={setIsOtpSend} email={email} setResetEmail={setResetEmail} closeModal={closeModal} setChangeEmail={setChangeEmail} reset={reset} setEmail={setEmail}/> */}
                    <OtpInput authModal={authModal} setVerifyOtp={setVerifyOtp} setIsOtpSend={setIsOtpSend} email={email} setResetEmail={setResetEmail} closeModal={closeModal} setChangeEmail={setChangeEmail} reset={reset} setEmail={setEmail}/>
                </div>
                </div>
                }

                { authModal==="resetPasswordModal" && 
                <div  className="h-full flex flex-col justify-center px-2 ">
                    <div className="w-full lg:w-[65%] dark:lg:w-full min-h-52 rounded-xl drop-shadow-lg bg-white mx-auto flex justify-center items-center flex-col text-center py-3 px-4 relative dark:bg-[#151515]">
                    <div className="absolute top-0 right-3 lg:flex justify-end items-center my-2  ">
                            <CancelOutlinedIcon onClick={()=>closeModal()} className=" text-[#D8D8D8] cursor-pointer !w-7 !h-7" />
                        </div>
                        <h2 className="text-black text-sm lg:text-2xl my-3 dark:text-white">Reset your password.</h2>
                            <ResetPasswordModal cpassword={cpassword} handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} errors={errors}/>
                    </div>
                </div>
                }
               </div>
            </Modal>
            <div className={` duration-700 z-[99999] w-[70%] rounded-tl-3xl p-8 lg:p-0 rounded-bl-3xl top-0 fixed ${isMobileLoginSideBar?"right-0":"-right-[100%]"} bg-white shadow-2xl h-full dark:bg-[#151515]`}>
                <div className=" text-[#312522] font-normal text-[16px]">
                    <Link onClick={()=>{setIsMobileLoginSideBar(false);handleLogin()}} to="/premium" className="block hover:text-[#F4AE45] lg:inline-block dark:text-white">Premium</Link>
                    <Link to="/pro"  onClick={()=>{setIsMobileLoginSideBar(false); handleLogin()}} className="my-1 block hover:text-[#F4AE45] lg:inline-block dark:text-white">Pro</Link>
                    {!tokenStatus?<div className="">
                        <Button1 className=" dark:text-white dark:hover:!text-white" onClick={()=>handleOpen("signUp")}>Sign Up</Button1>
                        <Button1 className=" dark:text-white dark:hover:!text-white" onClick={()=>handleOpen("login")}>Login</Button1>
                    </div>:
                    <div className="">
                        <div className="flex items-center justify-start gap-x-2 cursor-pointer " onClick={()=>setUserDetailModal(pre=>!pre)}>
                        <Tooltip title={profileNameCapitalize || "User Name"}>
                            <AccountCircleIcon className="dark:text-white"/>
                        </Tooltip>

                            {/* <p className="dark:text-white">{profileData?.v_name?profileData?.v_name:"User Name"}</p> */}
                            {/* <KeyboardArrowDownIcon className="dark:text-white"/> */}
                        </div>
                         <Link to="/user-account" className="block dark:text-white" onClick={()=>setIsMobileLoginSideBar(false)}>
                            Account
                        </Link>
                        <Link to='/subscription' className="block dark:text-white" onClick={()=>setIsMobileLoginSideBar(false)}>
                            Subscripition
                        </Link>
                        <Link to="/setting" className="block dark:text-white" onClick={()=>setIsMobileLoginSideBar(false)}>
                            Settings
                        </Link>
                        <div className=" dark:text-white" onClick={()=>setIsCreateModal(true)}>
                            Sign out
                        </div>
                    </div>
                    }
                </div>
            </div>
            <div className="flex justify-between items-center">
                <MenuIcon className="lg:!hidden !block dark:!text-white" onClick={()=>handleMobileSidebar()}/>

                {/* GlobalSearchBar */}
                <GlobalSearchBar handleLogin={handleLogin} setExpanded={setExpanded} expanded={expanded} searchValue={searchValue}/>
                <div className="relative mx-3 lg:hidden">
                    <NotificationsIcon  onClick={()=>{handleNotiPopUpMobile();handleCommunityClick()}}  className={`hover:text-[#F4AE45] dark:text-[#DDD1CE] cursor-pointer duration-500 ${animate ? "bell-shake !text-3xl" : ""}`}/>
                    
                    {notificationCount>0 && <div className="absolute top-0 flex justify-center items-center  py-[1px] px-[2px] right-0 rounded-full bg-[#40BE88]">
                       {/* <p className="text-[8px] ">{notificationbell?.length + notificationbellCommunity?.length}</p>  */}
                       <p className="text-[8px] ">{notificationCount}</p> 
                    </div>}
                  
                    {notificationPopUpMobile && <div  className="fixed top-0 left-0 dark:text-white dark:bg-[#151515] bg-white drop-shadow-2xl rounded-2xl z-50 w-full h-full">
                        <div className="relative">

                        <div className="w-full py-5 relative"><KeyboardBackspaceIcon onClick={()=>setNotificationPopUpMobile(false)}  className="absolute top-2 left-2 !text-[#D8D8D8] "/></div>
                        <img src="/right.png" alt="" className="absolute top-0 hidden -right-[2px]" />
                        <div className="grid lg:grid-cols-1 grid-cols-1  content-center text-center  text-[#312522] text-lg font-bold gap-x-1 my-5">
                            {/* <p className={`cursor-pointer ${nav===1?"text-[#F1B942] dark:text-[#DDD1CE] font-bold":"dark:text-[#DDD1CE] text-[#A1A1A1]"} `} onClick={()=>setNav(1)}>Overview</p> */}
                            <p className={`cursor-pointer ${nav===2?"text-[#F1B942] dark:text-[#DDD1CE] font-bold":"dark:text-[#DDD1CE] text-[#A1A1A1]"} `} onClick={()=>{setNav(2);handleCommunityClick()}}>Notification</p>
                            {/* <p className={`cursor-pointer ${nav===3?"text-[#F1B942] dark:text-[#DDD1CE] font-bold":"dark:text-[#DDD1CE] text-[#A1A1A1]"} `} onClick={()=>setNav(2)}>Comments</p> */}
                        </div>
                        <div className="w-full h-[5px] bg-white dark:bg-[#333333] grid  lg:grid-cols-1 grid-cols-1 ">
                            {/* <div className={`${nav===1?"bg-[#F1B942]":""}`}></div> */}
                            <div className={`${nav===2?"bg-[#F1B942]":""}`}></div>
                            {/* <div className={`${nav===3?"bg-[#F1B942]":""}`}></div> */}
                        </div>
                        </div>
                        {/* {(nav===1 &&  notificationbell?.length>0 ) ?
                        <div className=" h-[72%] mb-10 lg:mb-0 lg:max-h-72 min-h-56 mt-5  overflow-y-auto relative">
                        {notificationbell?.map((noti,index)=>{
                            return(
                            <div key={index} className="flex px-5 justify-between items-center w-full max-h-14 my-5">
                            <div className="flex justify-center items-center gap-x-4">
                            <div className="w-10 h-10 rounded-full">
                                <img src="/notificationLogo.jpg" alt="" className="w-full h-full rounded-full" />
                            </div>
                            <div>
                                <p className="lg:text-base font-semibold text-sm">{noti?.title}</p>
                                <p  className="lg:text-sm text-xs">{noti?.msg}</p>
                                <p className="text-xs">{noti?.date}</p>
                            </div>
                            </div>
                            <div className="flex justify-end items-center">
                                <CloseIcon className="cursor-pointer" onClick={()=> dispatch(removeNotification(noti?.id))}/>
                            </div>
                        </div>
                            )
                        })}
                        <div onClick={()=>dispatch(clearAllNotifications())} className="fixed top-28 lg:bottom-3 right-3 ">
                            <p className="text-sm font-semibold cursor-pointer">Clear All</p>
                        </div>
                        </div>
                        :
                        <div className={`flex ${nav===1?"block":"hidden"} flex-col justify-center items-center h-60 text-center`}>
                            <div>
                              
                                 <div className="flex justify-center items-center">
                                    <img src="/ArtboardBell.png" alt="" className="" />
                                </div>
                                <p className="text-base font-medium my-2">No Notification yet </p>
                                <p className="text-xs font-medium text-[#A8A8A8] my-2">Youll see notification here when they are
                                available.</p>
                            </div>
                        </div>
                        } */}

                    {(nav===2 &&  notificationbellCommunity?.length>0 ) ?
                        <div className=" h-[75%] px-5 lg:px-0  lg:max-h-72 lg:min-h-56 mt-5  overflow-y-auto relative">
                        {notificationbellCommunity?.map((noti,index)=>{
                            return(
                            <Link  to={`/other-user/${noti?.id}`} key={index}  className={`flex ${noti?.category==="pending_followings" && "hidden"} justify-between items-center w-full max-h-14 my-1 ${!noti?.is_read?"bg-[#FFFAEF]":""} py-11 px-5 rounded-2xl`}>
                            <div className="flex justify-start lg:justify-center items-center gap-x-4 w-[60%] lg:w-full">
                            <div className="min-w-10 max-w-10 max-h-10 min-h-10 rounded-full overflow-hidden ">
                                <img src={noti?.cover} alt="" className="w-full h-full rounded-full object-cover" />
                            </div>
                            <div className="">
                                {/* <p className="lg:text-base font-semibold text-sm line-clamp-1">{noti?.v_name?.length > 7 ? noti?.v_name?.slice(0, 10) + "..." : noti?.v_name}</p>
                                <p  className="lg:text-sm text-xs ">{noti?.category==="followings"?`${!noti?.is_private?"You have started following":noti?.v_name?.length > 7 ? noti?.v_name?.slice(0, 10) + "..." : noti?.v_name} ${!noti?.is_private?"":"has accepted your friend request"}`:noti?.category==="followers"?"Started Following you.":noti?.category==="pending_followings"?"":"Sent you a friend request"}</p> */}
                                <p className="lg:text-base font-semibold text-sm line-clamp-1">{noti?.content}</p>
                                <p className="text-xs">{noti?.date}</p>
                            </div>
                            </div>
                            {noti?.category==="pending_followers" &&<div className="flex justify-end items-center gap-x-6">
                                <div className="bg-[#D9D9D9] p-2 rounded-full flex justify-center items-center">
                                <CloseIcon className="cursor-pointer" onClick={()=>acceptRejectUser(noti?.id,"rejected")}/>
                                </div>
                                <div className=" bg-[#f1b942] p-2 rounded-full flex justify-center items-center">
                                <CheckIcon className="cursor-pointer" onClick={()=>acceptRejectUser(noti?.id,"accepted")}/>
                                </div>
                            </div>}
                            {noti?.category==="followers" && <div>
                            <Button1 onClick={()=>followUser(noti?.id)}>Follow back</Button1>    
                            </div>}
                        </Link>
                            )
                        })}
                        <div onClick={()=>dispatch(clearAllNotifications())} className="fixed top-3 lg:bottom-3 right-3">
                            <div></div>
                            {/* <p className="text-sm font-semibold cursor-pointer bg-white w-full py-2">Clear All</p> */}
                        </div>
                        </div>
                        :
                        <div className={`flex ${nav===2?"block":"hidden"} flex-col justify-center items-center h-60 text-center`}>
                            <div>
                                {/* <div>
                                    <img src="" alt="" className="" />
                                </div>
                                <p>No notification yet </p>
                                <p>Youll see notification here when they are
                                available.</p> */}
                                 <div className="flex justify-center items-center">
                                    <img src="/ArtboardBell.png" alt="" className="" />
                                </div>
                                <p className="text-base font-medium my-2">No Notification yet </p>
                                <p className="text-xs font-medium text-[#A8A8A8] my-2">Youll see notification here when they are
                                available.</p>
                            </div>
                        </div>
                        }
                    </div>}
                    </div>

                <div className="lg:flex justify-between hidden  items-center gap-x-10 text-[#312522] font-normal text-[16px]">
                    {/* <div id="google_translate_element" className="flex justify-center items-center"></div> */}
                    <Link className="hover:text-[#F4AE45] dark:text-[#DDD1CE]" onClick={()=>handleLogin()} to="/premium">Premium</Link>
                    <Link  className="hover:text-[#F4AE45] dark:text-[#DDD1CE]" onClick={()=>handleLogin()} to="/pro">Pro</Link>
                    <div className="relative">
                    <NotificationsIcon ref={bellRefs} onClick={()=>{setNotificationPopUp(pre=>!pre);handleLogin();handleCommunityClick()}}  className={`hover:text-[#F4AE45] dark:text-[#DDD1CE] cursor-pointer duration-500 ${animate ? "bell-shake !text-3xl" : ""}`}/>
                    {notificationCount>0 && <div className="absolute flex justify-center items-center -top-1  h-3 w-3 -right-2 p-2 rounded-full text-white bg-[#40BE88]">
                        {/* <p>{notificationbell?.length + notificationbellCommunity?.length}</p> */}
                        <p className="text-[10px]">{notificationCount}</p>
                    </div>}
                    {notificationPopUp && <div ref={modalRefs} className="absolute top-7 p-5 bg-white drop-shadow-2xl rounded-2xl z-50 w-[500px] right-0 dark:bg-[#151515] dark:text-white">
                        <div className="relative">
                        <img src="/right.png" alt="" className="absolute top-0 -right-[2px] lg:hidden" />
                        <div className="grid lg:grid-cols-1 grid-cols-1  content-center text-center  text-[#312522] text-lg font-bold gap-x-1 my-1">
                            {/* <p className={`cursor-pointer ${nav===1?"text-[#F1B942] dark:text-[#DDD1CE] font-bold":"dark:text-[#DDD1CE] text-[#A1A1A1]"} `} onClick={()=>setNav(1)}>Overview</p> */}
                            <p className={`cursor-pointer ${nav===2?"text-[#F1B942] dark:text-[#DDD1CE] font-bold":"dark:text-[#DDD1CE] text-[#A1A1A1]"} `} onClick={()=>{setNav(2);handleCommunityClick()}}>Notifications</p>
                            {/* <p className={`cursor-pointer ${nav===3?"text-[#F1B942] dark:text-[#DDD1CE] font-bold":"dark:text-[#DDD1CE] text-[#A1A1A1]"} `} onClick={()=>setNav(2)}>Comments</p> */}
                        </div>
                        <div className="w-full h-[5px] bg-white dark:bg-[#333333] grid  lg:grid-cols-1 grid-cols-1 ">
                            {/* <div className={`${nav===1?"bg-[#F1B942] px-10":""}`}></div> */}
                            <div className={`${nav===2?"bg-[#F1B942] px-10":""}`}></div>
                            {/* <div className={`${nav===3?"bg-[#F1B942]":""}`}></div> */}
                        </div>
                        </div>
                        

                        {/* {(nav===1 &&  notificationbell?.length>0 ) ?
                        <div className=" max-h-72 min-h-56 mt-5  overflow-y-auto relative">
                        {notificationbell?.map((noti,index)=>{
                            return(
                            <div key={index} className="flex justify-between items-center w-full max-h-14 my-5">
                            <div className="flex justify-center items-center gap-x-4">
                            <div className="w-10 h-10 rounded-full">
                                <img src="/notificationLogo.jpg" alt="" className="w-full h-full rounded-full" />
                            </div>
                            <div>
                                <p className="lg:text-base font-semibold text-sm">{noti?.title}</p>
                                <p  className="lg:text-sm text-xs">{noti?.msg}</p>
                                <p className="text-xs">{noti?.date}</p>
                            </div>
                            </div>
                            <div className="flex justify-end items-center">
                                <CloseIcon className="cursor-pointer" onClick={()=> dispatch(removeNotification(noti?.id))}/>
                            </div>
                        </div>
                            )
                        })}
                        <div onClick={()=>dispatch(clearAllNotifications())} className="fixed bottom-3 right-3">
                            <p className="text-sm font-semibold cursor-pointer bg-white dark:bg-transparent dark:text-white w-full py-2">Clear all</p>
                        </div>
                        </div>
                        :
                        <div className={`flex ${nav===1?"block":"hidden"} flex-col justify-center items-center h-60 text-center`}>
                            <div>
                                <div className="flex justify-center items-center">
                                    <img src="/ArtboardBell.png" alt="" className="" />
                                </div>
                                <p className="text-base font-medium my-2">No Notification yet </p>
                                <p className="text-xs font-medium text-[#A8A8A8] my-2">Youll see notification here when they are
                                available.</p>
                            </div>
                        </div>
                        } */}

                    {(nav===2 &&  notificationbellCommunity?.length>0 ) ?
                        <div className=" max-h-72 min-h-56 mt-5  overflow-y-auto relative">
                        {notificationbellCommunity?.map((noti,index)=>{
                            return(
                            <div   className={`flex  justify-between items-center w-full max-h-14 my-1 ${!noti?.is_read?"bg-[#FFFAEF]":""} py-11 px-5 rounded-2xl`}>
                            <div className="flex justify-center items-center gap-x-4">
                            <Link to={`/other-user/${noti?.id}`} className="min-w-10 max-w-10  h-10 rounded-full">
                                <img src={noti?.cover} alt="" className="w-full h-full rounded-full" />
                            </Link>
                            <div className="">
                                <Link to={`/other-user/${noti?.id}`} className="lg:text-base font-semibold text-sm capitalize">{noti?.v_name?.length > 7 ? noti?.v_name?.slice(0, 10) + "..." : noti?.v_name}</Link>
                                {/* <p  className="lg:text-sm text-xs">{noti?.category==="followings"?`${!noti?.is_private?"You have started following":noti?.v_name?.length > 7 ? noti?.v_name?.slice(0, 10) + "..." : noti?.v_name} ${!noti?.is_private?"":"has accepted your friend request"}`:noti?.category==="followers"?"Started Following you.":noti?.category==="pending_followings"?"":"Sent you a friend request"}</p> */}

                                <p  className="lg:text-sm text-xs">{noti?.content}</p>
                                <p className="text-xs">{noti?.date}</p>
                            </div>
                            </div>
                            {noti?.category==="pending_followers" &&<div className="flex justify-end items-center gap-x-6">
                                <div className="bg-[#D9D9D9] p-2 rounded-full flex justify-center items-center">
                                <CloseIcon className="cursor-pointer" onClick={()=>acceptRejectUser(noti?.id,"rejected")}/>
                                </div>
                                <div className="bg-[#f1b942] p-2 rounded-full flex justify-center items-center">
                                <CheckIcon className="cursor-pointer "  onClick={()=>acceptRejectUser(noti?.id,"accepted")}/>
                                </div>
                            </div>}
                            {noti?.category==="followers" && <div>
                            <Button1 onClick={()=>followUser(noti?.id,noti?.is_follow_request_pending)}>{noti?.is_follow_request_pending?"Requested":"Follow back"}</Button1>    
                            </div>}
                        </div>
                            )
                        })}
                        <div onClick={()=>dispatch(clearAllNotifications())} className={`fixed bottom-3 right-3 ${nav===2?"hidden":"block"}`}>
                            <p className="text-sm font-semibold cursor-pointer bg-white w-full py-2">Clear All</p>
                        </div>
                        </div>
                        :
                        <div className={`flex flex-col justify-center items-center h-60 text-center`}>
                            <div>
                                {/* <div>
                                    <img src="" alt="" className="" />
                                </div>
                                <p>No notification yet </p>
                                <p>Youll see notification here when they are
                                available.</p> */}
                                 <div className="flex justify-center items-center">
                                    <img src="/ArtboardBell.png" alt="" className="" />
                                </div>
                                <p className="text-base font-medium my-2">No Notification yet </p>
                                <p className="text-xs font-medium text-[#A8A8A8] my-2">Youll see notification here when they are
                                available.</p>
                            </div>
                        </div>
                        }
                    </div>}
                    </div>

                    {!tokenStatus?<div className="flex justify-between items-center gap-x-2">
                        <Button2 className="dark:text-[#F3C25A]" onClick={()=>handleOpen("signUp")}>Sign Up</Button2>
                        <Button2 className="dark:text-[#F3C25A]" onClick={()=>handleOpen("login")}>Login</Button2>
                    </div>
                    :
                    <div className="relative">
                        <div className="flex items-center justify-center gap-x-2 cursor-pointer " onClick={()=>setUserDetailModal(pre=>!pre)}>
                        <Tooltip title={profileNameCapitalize || "User Name"}>
                            <AccountCircleIcon className="dark:text-white"/>
                        </Tooltip>
                            {/* <AccountCircleIcon className="dark:text-[#DDD1CE]"/> */}
                            {/* <p className="dark:text-[#DDD1CE]">{profileData?.v_name?profileData?.v_name:"User Name"}</p> */}
                            {/* <KeyboardArrowDownIcon className="dark:text-[#DDD1CE]"/> */}
                        </div>
                        {userDetailModal&&<div ref={modalRef} className="absolute p-4 text-[#312522] font-normal text-base top-[40px]  right-0 rounded-xl z-[99999] !bg-white drop-shadow-lg w-60 min-h-36 flex flex-col gap-2 dark:border-[1px] dark:border-[#343434] dark:!bg-[#151515] dark:text-[#DDD1CE]">
                                <Link to="/user-account" onClick={()=>setUserDetailModal(false)} className="flex justify-start gap-x-2 items-center hover:text-[#F1B942] cursor-pointer">
                                    <AccountCircleOutlinedIcon/>
                                    <p>Account</p>
                                </Link>

                                <Link 
                                    to='/subscription' 
                                    onClick={() => setUserDetailModal(false)}
                                    className="flex items-center gap-x-2 cursor-pointer group "
                                    >
                                    {/* Apply the custom class to your SVG */}
                                    <img 
                                        src="/images/subscriptionlogo.svg" 
                                        alt="" 
                                        className={`w-6 h-6 ${isDark?"svg-hover-color":"svg-hover-color-black svg-hover-color-black-normal "}
                                        `}
                                    />
                                    {/* Use Tailwind's group-hover to change text color on hover */}
                                    <p className="group-hover:text-[#F1B942]">Subscription</p>
                                    </Link>


                                <div onClick={()=>setUserDetailModal(false)} className="flex  justify-start gap-x-2 items-center cursor-pointer group">
                                    <img 
                                        src="/images/settingslogo.svg" 
                                        alt="" 
                                        className={`w-6 h-6 ${isDark?"svg-hover-color":"svg-hover-color-black svg-hover-color-black-normal"}
                                        `}
                                    />
                                    <Link className="group-hover:text-[#F1B942]" to="/setting">Settings</Link>
                                </div>
                                <div className="flex justify-start gap-x-2 items-center cursor-pointer group">
                                    <img 
                                        src="/images/sign-outlogo.svg" 
                                        alt="" 
                                        className={`w-6 h-6 ${isDark?"svg-hover-color":"svg-hover-color-black svg-hover-color-black-normal"}
`}                                    />
                                    
                                    <div className="group-hover:text-[#F1B942]" onClick={()=>setIsCreateModal(true)}>
                                        Sign out
                                    </div>
                                </div>
                        </div>}
                    </div>
                    }

                </div>
                <Dialog  visible={isCreateModal} className={`p-10 lg:h-auto w-[90vw] lg:w-[45vw] h-auto text-center !bg-white !opacity-1 ${isDark?"dark":""} dark:!bg-[#151515] drop-shadow-xl rounded-xl `} onHide={() => setIsCreateModal(false)}>
                <HighlightOffIcon onClick={() => setIsCreateModal(false)} className='cursor-pointer absolute top-3 right-3 dark:!text-white'/>
                <div className="flex flex-col justify-center h-full  ">
                <p className="lg:text-xl text-base font-semibold  dark:text-white">Do you want to logout?</p>
                <div className="flex justify-between items-center lg:px-32 mt-10 gap-x-5">
                    <button  onClick={()=>setIsCreateModal(false)}  className="bg-white text-[#1F2121] py-2 rounded-3xl lg:w-32 border-[1px] w-24 border-[#1F2121]">No</button>
                    <button onClick={()=>{setIsCreateModal(false);signOut()}} className="bg-[#1F2121] text-white py-2 rounded-3xl lg:w-32 w-24">Yes</button>
                </div>
                </div>
                </Dialog>
                <div className="lg:hidden">
                    <AccountCircleIcon className="dark:text-white" onClick={()=>setIsMobileLoginSideBar(pre=>!pre)}/>
                </div>
            </div>
            
            {/* {location.pathname!=='/' && (
                        <Link to={previousLocation} className="text-[#D8D8D8] my-3 gap-x-2 text-sm font-light flex justify-start items-center" >
                            <ArrowBackIcon className=""/> 
                            <span>Back to {previousLocation==="/"?"Home":""}</span>
                        </Link>
                    )} */}
        </div>
    )
}

export default HeaderComponent