import { useEffect, useState } from "react";
import ShareModal from "../../collectionPagesComponents/ShareModal"
import CommonPills from "../Pills/CommonPills"
import { useDispatch } from "react-redux";
import { handleOpenModal } from "../../../../store/modalSlice";
import Pills from "../../collectionPagesComponents/Pills";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useApi } from "../../../../api/api";
import { handleFollowerUserList } from "../../../../store/followerSlice";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { handleFollowingRequestList, handleFollowingUserList } from "../../../../store/followingSlice";
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { profileDetail } from "../../../../store/authenticationSlice";
import toast, { Toaster } from 'react-hot-toast';
import ShareModalGlobal from "../ShareModalGlobal/ShareModalGlobal";
import globalToast from "../../../../hooks/globalToast";
import { useLocation } from "react-router";


const TopDetailCard = ({image,titleName,duration,artistCount,decadeRange,playListCount,genresCount,platformsSynced,totalTracks,flag,userId,pillsData,Top,requestStatus,setOtherUserProfile,profession,follower_count,listener_count,is_own_profile}) => {
    const dispatch = useDispatch()
    const [imageFile, setImageFile] = useState(image);

    const handleUpdateProfile = async(imageFil) => {
        console.log("imageFiless",imageFile)

       
        // const formData = new FormData();
        // formData.append("v_profile_pic", imageFil);
            const payload = {
            v_profile_pic:imageFile
          }
        console.log("imageFilesspayload",payload)

          const profileUpdateRes = await handlePostRequest(`users/update-user-avatar`,payload, true, true);
          console.log("profileUpdateRes", profileUpdateRes);
          const profileDataReq = await handleGetRequest("profile-detail",false, true)
          dispatch(profileDetail(profileDataReq?.data))
          setEditStatus(false)
    }

  const { handleGetRequest,handlePostRequest } = useApi();
  const [followStatus,setFollowStatus] = useState()

    const [editStatus, setEditStatus] = useState(false);
    const [hoverLike, sethoverLike] = useState(false);
    const [id, setId] = useState("");

    const handleClickOpen = (id) => {
        dispatch(handleOpenModal())
        // setOpen(true);
        setId(id)
    };

    // const Top = {
    //     "Genres": [
    //         "Genres",
    //         "Genres",
    //         "Genres"
    //     ],

    //     "Artists":[
    //         "1 Artists Name ",
    //         "2 Artists Name ",
    //         "3 Artists Name ",
    //     ],

    //     "Tracks":[
    //         "1 Tracks Name ",
    //         "1 Tracks Name ",
    //         "1 Tracks Name "
    //     ]

    // }
    console.log("sssuserId",userId);
    const followUser = async(id) => {
        const playload = {
            user_id:userId
          }
        if(requestStatus==="pending"){
            const unFollowAcceptRes = await handleGetRequest(`removeFollowing/${userId}`,true,true)
            if(unFollowAcceptRes?.flag){
                const otherUserRes = await handlePostRequest(`profile-detail`,playload, false, false);
                console.log("otherUserRes", otherUserRes?.data?.data);
                setOtherUserProfile(otherUserRes?.data) 
              const followingUserRes = await handleGetRequest(`followings`,false,true)
              console.log("followingUserRes",followingUserRes?.data);
              dispatch(handleFollowingUserList(followingUserRes?.data))
              const pendingfollowingUserRes = await handleGetRequest(`pendingFollowings`,false,true)
              console.log("followingUserRes",pendingfollowingUserRes?.data);
              dispatch(handleFollowingRequestList(pendingfollowingUserRes?.data))
            }
        }else if (requestStatus==="notfollowed" || requestStatus==="declined") {
            const followUserRes = await handleGetRequest(`follow/${userId}`,false,true)
            console.log("unFollowUserRes",followUserRes);
            if(followUserRes?.flag){
                const otherUserRes = await handlePostRequest(`profile-detail`,playload, false, false);
                console.log("otherUserRes", otherUserRes?.data?.data);
                setOtherUserProfile(otherUserRes?.data) 
              const followerUserRes = await handleGetRequest(`followers`,false,true)
              console.log("followerUserResdasa",followerUserRes?.data);
              dispatch(handleFollowerUserList(followerUserRes?.data))
            }
        }else{
            const unFollowUserRes = await handleGetRequest(`unfollow/${userId}`,true,true)
            console.log("unFollowUserRes",unFollowUserRes);
            if(unFollowUserRes?.flag){
                const otherUserRes = await handlePostRequest(`profile-detail`,playload, false, false);
                console.log("otherUserRes", otherUserRes?.data?.data);
                setOtherUserProfile(otherUserRes?.data) 
              const followingUserRes = await handleGetRequest(`followings`,false,true)
                    console.log("followingUserRes",followingUserRes?.data);
                    dispatch(handleFollowingUserList(followingUserRes?.data))
            }
        }

  
      }

      console.log("globalProfileimage",image);
        const [imageUrl, setImageUrl] = useState("");
        // console.log("imageFile",imageFile)

      const handleImageChange = (event) => {
        
        const file = event.target.files[0];
        console.log("mmnn",file.name);
        const immgg = file.name
      
        const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
      
        let isValidFormat = false;
      
        if (immgg) {
          // Validate based on URL extension
          const urlExtension = immgg.split('.').pop().toLowerCase();
          console.log("urlExtension",urlExtension);
          
          isValidFormat = ['png', 'jpeg', 'jpg', 'webp'].includes(urlExtension);
        } else if (immgg) {
          // Validate based on file MIME type
          isValidFormat = allowedFormats.includes(immgg.type);
        }
      
        if (!isValidFormat) {
          // alert('Invalid image format. Please use PNG, JPEG, JPG, or WEBP.');
          globalToast("Invalid image format. Please use PNG, JPEG, JPG, or WEBP.")
          // setImageFile(image)
          return; // Exit the function if the format is invalid
        }
        
        if (file) {
            setImageFile(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrl(imageUrl);
        }
    };
    // /users/update-user-avatar
    // v_profile_pic


    // const shareId = `https://curioushit-frontend.nevinainfotech.in/other-user/${userId}`
    const [shareId,setShareId] = useState()


    const handleShareModalClose = () => {
        setShareId()
    }
    const location = useLocation();

    const handleShareProfile = () => {
        setShareId(`https://curioushit-frontend.nevinainfotech.in/other-user/${userId}`)
        const newUrl = `${location.pathname}?${Date.now()}`;
        window.history.pushState({}, '', newUrl);
    }


    useEffect(() => {
        const handlePopState = (event) => {
       //    callback(event);
       setShareId()
          
        };
    
        window.addEventListener('popstate', handlePopState);
    
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
      }, [])

    // console.log("Object.entries(Top)",Object.entries(Top).length);


    return(
        <div className=" lg:pt-14 lg:pb-7 py-7 w-full min-h-80 bg-white dark:bg-[#151515] rounded-xl p-3 gap-x-7 drop-shadow-xl relative lg:grid lg:grid-cols-12">
            {/* <ShareModal  id={`id`} location={`location`}/> */}
            <ShareModalGlobal userId={userId} location={shareId} open={shareId?true:false} handleClose={handleShareModalClose} />
            <div className={`absolute top-0 border-t-transparent right-[4%] border-[2px] flex justify-center items-center text-center ${flag==="global"?"h-12":"h-9"}    rounded-b-3xl border-[#F1B942] p-2`}>
                <div className="text-[8px] text-[#F1B942]">
                <p>{flag==="global"?follower_count:totalTracks || 0}</p>
                <p>{flag!=="global"?"Tracks":"Followers"}</p> 
                </div>
            </div>
            {flag==="global" &&<div className="absolute top-0 border-t-transparent right-[22%] lg:right-[10%] border-[2px] flex justify-center items-center text-center  h-12  rounded-b-3xl border-[#F1B942] p-2">
                <div className="text-[8px] text-[#F1B942]">
                <p>{listener_count}</p>
                <p>{"Listeners"}</p> 
                </div>
            </div>}

            <div className="col-span-4">
            <div className="justify-start hidden items-center gap-x-2 lg:flex absolute top-4">
                {profession?.map((items,index)=>{
                    return(
                        <button key={index} className="bg-[#F1B942] py-2 px-3 rounded-full text-white text-xs capitalize">{items}</button>
                      
                    )
                })}
            </div>
            

            {/* <div className={` w-[60%] mx-auto lg:w-[95%] lg:mx-0 h-full  ${flag==="global"?"rounded-full":""}`} >
                <img src={image} alt="" className={`object-cover w-full !h-full ${flag==="global"?"rounded-full":"rounded-xl "} `} />
            </div> */}

            <div className="lg:col-span-4 my-10 lg:my-0 lg:h-full h-[250px]  col-span-6 order-1 px-5 lg:order-2 relative">
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full">
                            <div className=" w-full h-full relative ">
                               { flag==="global" ?<img
                                    src={image}
                                    alt="Preview"
                                    className={`w-full h-full object-cover rounded-2xl  ${!image ? "animate-pulse bg-gray-300" : ""}`}
                                />: <img
                                src={imageUrl?imageUrl:imageFile}
                                alt="Preview"
                                className="w-full h-full object-cover rounded-2xl"
                            />}
                                {editStatus && <div className={`absolute top-0 right-0 min-w-10 ${flag==="global"?"hidden":"block"}`}>
                                    <div className="relative w-5 h-5">
                                    <input
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="!opacity-0 w-7 absolute top-0 rounded-2xl"
                                    />
                                   
                                    <BorderColorOutlinedIcon className="!text-[#312522]"/>
                                    </div>
                                </div>}
                                <div>
                                </div>
                            </div>


                            
                    </div>
                    </div>

            {/* <div className="flex justify-center items-center my-5">
                <button className="bg-[#F1B942] lg:hidden py-2 px-3 rounded-full hover:border-[1px] hover:bg-white hover:text-[#F1B942] hover:border-[#F1B942] text-white text-xs">Educator</button>
            </div> */}

            <div className=" lg:hidden flex justify-center items-center gap-x-1 mb-3">
                {profession?.map((items,index)=>{
                    return(
                        <button key={index} className="bg-[#F1B942] py-2 px-3 rounded-full hover:border-[1px] hover:bg-white hover:text-[#F1B942] hover:border-[#F1B942] text-white text-xs capitalize">{items}</button>
                      
                    )
                })}
            </div>
            </div>

            <div className="col-span-8 flex flex-col h-full">
                {/* <h3 className="text-[#312522] text-center lg:!text-left text-xl font-semibold mb-4">{titleName}</h3> */}
                <div className="flex justify-center gap-y-3 flex-wrap lg:flex-nowrap lg:justify-start items-center gap-x-2 ">
                    <div className="flex justify-center gap-y-3 flex-wrap lg:flex-nowrap lg:justify-start items-center gap-x-2 ">
                        {/* {["Artists10", "Decades : 10’s - 30’s", "Album 3","Geos  2","Default"]?.map((items,index)=>{
                            return(
                                <Pills className="bg-[#FFEBD4] ">{items}</Pills>
                            )
                        })} */}

                        {pillsData?.map((items,index)=>{
                            return(
                                <Pills className="bg-[#FFEBD4]">{items}</Pills>
                            )
                        })}
                    </div>
                </div>

                <div className={`grid  gap-x-2 my-3 ${
                    Object?.entries(Top || {})?.length === 3 
                    ? "grid-cols-3" 
                    : Object?.entries(Top || {})?.length === 4 
                    ? "grid-cols-4" 
                    : Object?.entries(Top || {})?.length === 2 
                    ? "grid-cols-2" 
                    : "grid-cols-1"
                } mx-10 lg:mx-0 lg:grid-cols-4`}>
                    {Top && Object?.entries(Top)?.map(([key, value]) => (
                        <div key={key} className={`my-3 ${value?.length > 0 ?"":"hidden"}`}>
                        {/* {value?.length > 0 &&  <h2 className="text-[#A1A1A1]  text-sm font-normal my-3">{key}</h2>} */}
                        {value?.length > 0 &&  <h2 className={`text-[#A1A1A1] capitalize ${key==="Genres"?"text-center":""} text-sm font-normal my-3 `}>{key}</h2>}
                        {value?.length > 0 && (
                            <ol className="">
                            {value?.map((item, index) => (
                                // <li className="text-xs list-decimal my-1 text-[#A1A1A1] line-clamp-1 font-light" key={index}>{index+1}. {item}</li>
                                <li className={`text-xs ${key==="artists"?"font-semibold !text-sm":""} list-decimal  line-clamp-1  text-[#A1A1A1] font-light ${key==="Genres"?"bg-white   text-center py-1 rounded-2xl  !text-[#3871E4] border-[1px]  mx-auto border-[#3871E4] my-1":""} `} key={index}>{key==="Genres"?"":index+1}{key==="Genres"?"":"."}  {item}</li>
                            ))}
                            </ol>
                        )}
                        </div>
                    ))}
                </div>
               
                
                <div className="bg-[#312522] dark:bg-[#DDD1CE] flex lg:justify-end justify-center items-center lg:!mt-auto mt-5 h-[75px] lg:h-[90px] rounded-lg">
                    <div className="flex lg:justify-end justify-center  items-center gap-x-3 px-3">
                        {flag==="global"?<div>
                            {!is_own_profile && <button onClick={()=>followUser(id)} className="max-w-[150px] bg-white px-3 py-2 rounded-3xl flex justify-between items-center gap-x-2 text-[#F1B942] dark:bg-[#0D0D0D] dark:text-[#F3C25A]" >
                            {requestStatus==="pending"?"Requested":(requestStatus==="notfollowed" ||requestStatus==="declined")?"Follow":"Unfollow"}
                            </button>}
                            </div>
                            :
                            <div>
                            {!editStatus ?<div>
                            <button onClick={()=>setEditStatus(true)} className="max-w-[150px] bg-white px-3 py-2 rounded-3xl flex justify-between items-center gap-x-2 text-[#F1B942] dark:bg-[#0D0D0D] dark:text-[#F3C25A]" >
                            <EditOutlinedIcon/> Edit
                            </button>
                            </div>:<div>
                            <button onClick={()=>handleUpdateProfile(imageFile)} className="max-w-[150px] bg-white px-3 py-2 rounded-3xl flex justify-between items-center gap-x-2 text-[#F1B942] dark:bg-[#0D0D0D] dark:text-[#F3C25A]" >
                            Save
                            </button>
                            </div>}
                        </div>}

                        <ShareOutlinedIcon onClick={()=>handleShareProfile()} className="!text-white hover:!text-[#F1B942] p-2 !w-12 !h-12 lg:!w-10 lg:!h-10 rounded-full  border-[2px] !border-white hover:!border-[#F1B942] cursor-pointer dark:!text-[#0D0D0D] dark:!border-[#0D0D0D]" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopDetailCard