import { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from "../../../../api/api";
import { useParams } from "react-router";
import globalToast from "../../../../hooks/globalToast";


const ShareModalGlobal =  ({location,handleClose,open,isglobal=false}) => {
      // const { id } = useParams()
      const id = localStorage.getItem('itemId');
      const [globalTab, setGlobalTab] = useState(); 

      const storedData = localStorage.getItem('globalTab');
      useEffect(()=>{
        if (storedData && isglobal) {
          console.log("sdsfsd",isglobal,storedData);
    
            setGlobalTab(JSON.parse(storedData)); // Parse and set the data
          }
      },[storedData])
    

      console.log("sdsfsdfslocation",location);
     
      const parts = location?.split("/");
      const [inputValue, setInputValue] = useState(`${location}`);
      const [copied, setCopied] = useState(false); 
      console.log("inputValue",inputValue);
      const { handleGetRequest } = useApi();


  
      const BootstrapDialog = styled(Dialog)(({ theme }) => ({
          '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
          },
          '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
          },
        }));
  
       
      
      const handleInputChange = (e) => {
          setInputValue(e.target.value);
        };
      
        const handleShare = (platform) => {
          switch (platform) {
            case 'whatsapp':
              shareOnWhatsApp();
              break;
            case 'facebook':
              shareOnFacebook();
              break;
            case 'gmail':
              shareOnGmail();
              break;
            case 'reddit':
              shareOnReddit();
              break;
            default:
              break;
          }
        };
      
        const shareOnWhatsApp = async() => {
         
          if(isglobal){
            const whatsappURL =   `https://api.whatsapp.com/send?text=${`https://curioushit-frontend.nevinainfotech.in/collection/${globalTab}/${parts[parts.length - 1]}`}`;
            window.open(whatsappURL, '_blank');
            const shareWhatsApp = await handleGetRequest(`${globalTab}-share?id=${id}`,false,false)

          }else{
            const whatsappURL =   `https://api.whatsapp.com/send?text=${location}`;
            window.open(whatsappURL, '_blank');
            const shareWhatsApp = await handleGetRequest(`${parts[parts.length - 2]}-share?id=${id}`,false,false)
          }
        };
      
        const shareOnFacebook = async() => {
            
            // const shareFacebook = await handleGetRequest(`${parts[parts.length - 2]}-share?id=${id}`,false,false)
            if(isglobal){
              const facebookURL =`https://www.facebook.com/sharer/sharer.php?u=${`https://curioushit-frontend.nevinainfotech.in/collection/${globalTab}/${parts[parts.length - 1]}`}`;
              window.open(facebookURL, '_blank');
              const shareWhatsApp = await handleGetRequest(`${globalTab}-share?id=${id}`,false,false)
  
            }else{
              const facebookURL =`https://www.facebook.com/sharer/sharer.php?u=${location}`;
              window.open(facebookURL, '_blank');
              const shareWhatsApp = await handleGetRequest(`${parts[parts.length - 2]}-share?id=${id}`,false,false)
            }
        };
      
        // const shareOnGmail = async() => {
        //     const gmailURL = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to&su=Check%20this%20out&body=${location}`;
        //     window.open(gmailURL, '_blank');
        //     const shareGmail = await handleGetRequest(`${parts[parts.length - 2]}-share?id=${id}`,false,false)
        // };

        const shareOnGmail = async () => {
         
        
        
          // Perform the API call after opening Gmail
          // const shareGmail = await handleGetRequest(`${parts[parts.length - 2]}-share?id=${id}`, false, false);
          if(isglobal){
            const location = encodeURIComponent(/* ... get the location value */);
          
            // Check if the device is mobile
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
          
            let url;
            if (isMobile) {
              // For mobile devices, use the Gmail app's custom URI scheme
              url = `googlegmail:///co?subject=Check%20this%20out&body=${`https://curioushit-frontend.nevinainfotech.in/collection/${globalTab}/${parts[parts.length - 1]}`}`;
            } else {
              // For desktop, use the web-based Gmail interface
              url = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to&su=Check%20this%20out&body=${`https://curioushit-frontend.nevinainfotech.in/collection/${globalTab}/${parts[parts.length - 1]}`}`;
            }
          
            // Open the URL
            window.location.href = url;
            const shareWhatsApp = await handleGetRequest(`${globalTab}-share?id=${id}`,false,false)

          }else{
            const location = encodeURIComponent(/* ... get the location value */);
          
            // Check if the device is mobile
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
          
            let url;
            if (isMobile) {
              // For mobile devices, use the Gmail app's custom URI scheme
              url = `googlegmail:///co?subject=Check%20this%20out&body=${location}`;
            } else {
              // For desktop, use the web-based Gmail interface
              url = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to&su=Check%20this%20out&body=${location}`;
            }
          
            // Open the URL
            window.location.href = url;
            const shareWhatsApp = await handleGetRequest(`${parts[parts.length - 2]}-share?id=${id}`,false,false)
          }
        };
        
      
        const shareOnReddit = async() => {
          
            // const shareReddit = await handleGetRequest(`${parts[parts.length - 2]}-share?id=${id}`,false,false)
            if(isglobal){
              const redditURL = `https://www.reddit.com/submit?url=${`https://curioushit-frontend.nevinainfotech.in/collection/${globalTab}/${parts[parts.length - 1]}`}&title=Check%20this%20out`;
              window.open(redditURL, '_blank');
              const shareWhatsApp = await handleGetRequest(`${globalTab}-share?id=${id}`,false,false)
             
  
            }else{
              const redditURL = `https://www.reddit.com/submit?url=${location}&title=Check%20this%20out`;
              window.open(redditURL, '_blank');
              const shareWhatsApp = await handleGetRequest(`${parts[parts.length - 2]}-share?id=${id}`,false,false)
             
            }
        };
    
        // const handleCopyClick = () => {
        //     navigator.clipboard.writeText(location)
        //       .then(() => {
        //         alert('Copied to clipboard!');
        //       })
        //       .catch((error) => {
        //         console.error('Error copying to clipboard: ', error);
        //       });
        //   };
        const handleCopyClick = async() => {
          if (navigator.clipboard && navigator.clipboard.writeText) {
              // alert("Copied !!")
              // toast.success("Copied !!");
          // const shareWhatsApp = await handleGetRequest(`${parts[parts.length - 2]}-share?id=${id}`,false,false)
          if(isglobal){
            const shareWhatsApp = await handleGetRequest(`${globalTab}-share?id=${id}`,false,false)
            navigator.clipboard.writeText(`https://curioushit-frontend.nevinainfotech.in/collection/${globalTab}/${parts[parts.length - 1]}`)


          }else{
            const shareWhatsApp = await handleGetRequest(`${parts[parts.length - 2]}-share?id=${id}`,false,false)
            navigator.clipboard.writeText(location)

          }

              globalToast("Copied !!")
            }

            // const shareFacebook = await handleGetRequest(`${parts[parts.length - 2]}-share?id=${id}`,false,false)
                  // .then(() => {
                  //     setCopied(true);
                  //     setTimeout(() => setCopied(false), 2000);
                  // })
                  // .catch((error) => {
                  //     console.error('Error copying to clipboard: ', error);
                  // });
          // } else {
          //     const textarea = document.createElement('textarea');
          //     textarea.value = location;
          //     textarea.style.position = 'fixed'; // Ensure it is invisible
          //     document.body.appendChild(textarea);
          //     textarea.focus();
          //     textarea.select();
      
          //     try {
          //         const success = document.execCommand('copy');
          //         if (success) {
          //             setCopied(true);
          //             setTimeout(() => setCopied(false), 2000);
          //         } else {
          //             console.error('Copy command failed');
          //         }
          //     } catch (error) {
          //         console.error('Error copying to clipboard: ', error);
          //     } finally {
          //         document.body.removeChild(textarea);
          //     }
          // }
      };
      
  
         
    return(
        <BootstrapDialog
            open={open}
            onClose={handleClose}
            className="dark"
            >
                <div className=" lg:w-[600px] p-3 lg:p-0 w-full flex-wrap lg:flex-nowrap min-h-[348px] bg-white dark:bg-[#1B1B1B] flex flex-col justify-center items-center">
                    <div className="absolute top-0 p-3 cursor-pointer right-0" onClick={()=>handleClose()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M26 14C26 7.375 20.625 2 14 2C7.375 2 2 7.375 2 14C2 20.625 7.375 26 14 26C20.625 26 26 20.625 26 14Z" stroke="#D8D8D8" stroke-width="2.5" stroke-miterlimit="10"/>
                    <path d="M18 18L10 10" stroke="#D8D8D8" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 18L18 10" stroke="#D8D8D8" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </div>
                    <div>
                        <p className="text-[#222] text-[24px] font-semibold mb-5 dark:text-white">Share</p>
                        <div className="flex flex-wrap lg:flex-nowrap justify-between items-center gap-x-3 lg:gap-x-10">
                            <div onClick={() => handleShare('gmail')} className="cursor-pointer">
                                <div className="bg-[#F4F2ED] w-[64px] h-[64px] rounded-full flex justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 32 24" fill="none">
                                <path d="M7.27275 23.8822V11.5825L3.43838 8.09279L0 6.15625V21.7116C0 22.9126 0.978125 23.8822 2.18188 23.8822H7.27275Z" fill="#4285F4"/>
                                <path d="M24.7266 23.8821H29.8174C31.0248 23.8821 31.9993 22.9089 31.9993 21.7115V6.15625L28.1048 8.37432L24.7266 11.5824V23.8821Z" fill="#34A853"/>
                                <path d="M7.2737 11.5813L6.75195 6.77545L7.2737 2.17578L16.001 8.68737L24.7282 2.17578L25.3118 6.52712L24.7282 11.5813L16.001 18.0929L7.2737 11.5813Z" fill="#EA4335"/>
                                <path d="M24.7266 2.17662V11.5821L31.9993 6.1559V3.26185C31.9993 0.577703 28.9193 -0.952453 26.7631 0.657288L24.7266 2.17662Z" fill="#FBBC04"/>
                                <path d="M0 6.15596L3.34488 8.65171L7.27275 11.5822V2.17668L5.23625 0.657345C3.07625 -0.95252 0 0.577759 0 3.26178V6.15596Z" fill="#C5221F"/>
                                </svg>
                                </div>
                                <p className="text-center text-[#34A853] text-[12px] font-normal mt-2">Gmail</p>
                            </div>
                            <div onClick={() => handleShare('facebook')}  className="cursor-pointer">
                                <div className="bg-[#1877F21A] w-[64px] h-[64px] rounded-full flex justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="24" viewBox="0 0 13 24" fill="none">
                                    <path d="M12.0117 13.5L12.6783 9.15656H8.51063V6.33797C8.51063 5.14969 9.09281 3.99141 10.9594 3.99141H12.8541V0.293438C12.8541 0.293438 11.1347 0 9.49078 0C6.05859 0 3.81516 2.08031 3.81516 5.84625V9.15656H0V13.5H3.81516V24H8.51063V13.5H12.0117Z" fill="#1877F2"/>
                                </svg>
                                </div>
                                <p className="text-center text-[#1877F2] text-[12px] font-normal mt-2">Facebook</p>
                            </div>
                            <div onClick={() => handleShare('reddit')}  className="cursor-pointer">
                                <div className="bg-[#FF45001A] w-[64px] h-[64px] rounded-full flex justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                                <path d="M21.3237 8.6803C20.565 8.6803 19.8973 8.99392 19.4066 9.48459C17.6007 8.23515 15.1676 7.43086 12.4715 7.34487L13.8726 1.03192L18.3342 2.0335C18.3342 3.12612 19.2245 4.01641 20.3171 4.01641C21.43 4.01641 22.3253 3.10083 22.3253 2.0082C22.3253 0.915579 21.435 0 20.3171 0C19.5381 0 18.8653 0.470436 18.5315 1.11286L13.6046 0.0202338C13.3567 -0.045526 13.1139 0.13152 13.0481 0.379384L11.5104 7.33981C8.83444 7.45109 6.42661 8.25539 4.61569 9.50482C4.12502 8.99392 3.43201 8.6803 2.67325 8.6803C-0.13925 8.6803 -1.05989 12.4539 1.51486 13.7438C1.42381 14.1434 1.38334 14.5683 1.38334 14.9932C1.38334 19.2322 6.15852 22.6669 12.0213 22.6669C17.9093 22.6669 22.6845 19.2322 22.6845 14.9932C22.6845 14.5683 22.6389 14.1232 22.5277 13.7236C25.0518 12.4286 24.1211 8.6803 21.3237 8.6803ZM5.59703 14.0119C5.59703 12.899 6.48732 12.0037 7.60523 12.0037C8.69786 12.0037 9.58815 12.894 9.58815 14.0119C9.58815 15.1045 8.69786 15.9948 7.60523 15.9948C6.49237 15.9999 5.59703 15.1045 5.59703 14.0119ZM16.4373 18.7415C14.596 20.5828 9.40098 20.5828 7.55971 18.7415C7.35737 18.5645 7.35737 18.2509 7.55971 18.0485C7.73675 17.8715 8.05038 17.8715 8.22742 18.0485C9.63367 19.4902 14.2976 19.5155 15.7645 18.0485C15.9416 17.8715 16.2552 17.8715 16.4322 18.0485C16.6396 18.2509 16.6396 18.5645 16.4373 18.7415ZM16.3968 15.9999C15.3042 15.9999 14.4139 15.1096 14.4139 14.017C14.4139 12.9041 15.3042 12.0088 16.3968 12.0088C17.5097 12.0088 18.405 12.899 18.405 14.017C18.4 15.1045 17.5097 15.9999 16.3968 15.9999Z" fill="#FF4500"/>
                                </svg>
                                </div>
                                <p className="text-center text-[#FF4500] text-[12px] font-normal mt-2">Reddit</p>
                            </div>
                            <div onClick={() => handleShare('whatsapp')}  className="cursor-pointer">
                                <div className="bg-[#25D3661A] w-[64px] h-[64px] rounded-full flex justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M20.4054 3.4875C18.1607 1.2375 15.1714 0 11.9946 0C5.4375 0 0.101786 5.33571 0.101786 11.8929C0.101786 13.9875 0.648214 16.0339 1.6875 17.8393L0 24L6.30536 22.3446C8.04107 23.2929 9.99643 23.7911 11.9893 23.7911H11.9946C18.5464 23.7911 24 18.4554 24 11.8982C24 8.72143 22.65 5.7375 20.4054 3.4875ZM11.9946 21.7875C10.2161 21.7875 8.475 21.3107 6.95893 20.4107L6.6 20.1964L2.86071 21.1768L3.85714 17.5286L3.62143 17.1536C2.63036 15.5786 2.11071 13.7625 2.11071 11.8929C2.11071 6.44464 6.54643 2.00893 12 2.00893C14.6411 2.00893 17.1214 3.0375 18.9857 4.90714C20.85 6.77679 21.9964 9.25714 21.9911 11.8982C21.9911 17.3518 17.4429 21.7875 11.9946 21.7875ZM17.4161 14.3839C17.1214 14.2339 15.6589 13.5161 15.3857 13.4196C15.1125 13.3179 14.9143 13.2696 14.7161 13.5696C14.5179 13.8696 13.95 14.5339 13.7732 14.7375C13.6018 14.9357 13.425 14.9625 13.1304 14.8125C11.3839 13.9393 10.2375 13.2536 9.08571 11.2768C8.78036 10.7518 9.39107 10.7893 9.95893 9.65357C10.0554 9.45536 10.0071 9.28393 9.93214 9.13393C9.85714 8.98393 9.2625 7.52143 9.01607 6.92679C8.775 6.34821 8.52857 6.42857 8.34643 6.41786C8.175 6.40714 7.97679 6.40714 7.77857 6.40714C7.58036 6.40714 7.25893 6.48214 6.98571 6.77679C6.7125 7.07679 5.94643 7.79464 5.94643 9.25714C5.94643 10.7196 7.0125 12.1339 7.15714 12.3321C7.30714 12.5304 9.25179 15.5304 12.2357 16.8214C14.1214 17.6357 14.8607 17.7054 15.8036 17.5661C16.3768 17.4804 17.5607 16.8482 17.8071 16.1518C18.0536 15.4554 18.0536 14.8607 17.9786 14.7375C17.9089 14.6036 17.7107 14.5286 17.4161 14.3839Z" fill="#25D366"/>
                                </svg>
                                </div>
                                <p className="text-center text-[#25D366] text-[12px] font-normal mt-2">WhatsApp</p>
                            </div>
                        </div>
                        <div className="relative mt-5">
                           <input
                                type="text"
                                value={location}
                                onChange={handleInputChange}
                                disabled
                                placeholder="https://example.com/article/social-share-modal"
                                className="border border-gray-300 rounded-md px-4 py-3 w-full "
                            />
                            <div
                             onClick={()=>handleCopyClick()}
                             className="absolute bg-white  right-1 top-3 cursor-pointer px-2 py-2  text-white rounded-md"
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M15.3996 6.60156H8.19961C7.31595 6.60156 6.59961 7.31791 6.59961 8.20156V15.4016C6.59961 16.2852 7.31595 17.0016 8.19961 17.0016H15.3996C16.2833 17.0016 16.9996 16.2852 16.9996 15.4016V8.20156C16.9996 7.31791 16.2833 6.60156 15.3996 6.60156Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3.4 11.4H2.6C2.17565 11.4 1.76869 11.2314 1.46863 10.9314C1.16857 10.6313 1 10.2243 1 9.8V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1H9.8C10.2243 1 10.6313 1.16857 10.9314 1.46863C11.2314 1.76869 11.4 2.17565 11.4 2.6V3.4" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </div>
                            {copied && (
                            <div className="absolute bg-gray-800 text-white px-2 py-1 rounded-md -top-10 -right-1">
                              Copied
                            </div>
                          )}
                        </div>
                    </div>
                </div>
            </BootstrapDialog>
    )
}

export default ShareModalGlobal


