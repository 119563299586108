import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from 'react-redux';
import H220 from '../Typograpgy/H220';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Button1 from '../GlobalButtonLayout/Button1';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useApi } from '../../../../api/api';
import { useLocation, useParams } from 'react-router';
import globalToast from '../../../../hooks/globalToast';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const EditPlaylist = ({openEditPlaylistModal,setOpenEditPlaylistModal,image,name,managePlaylistData,oldData,setOldData,playlistName,setPlaylistName,setManagePlaylistData,t_description,setPlaylistDetailData,title}) => {
  const { handleGetRequest,handlePostRequest } = useApi();
  const { id } = useParams()
  const location = useLocation();
  const parts = location.pathname.split("/");
  const searchglobalId = location?.search
  const globalId = searchglobalId.includes("globalid=true");
const [imageFile, setImageFile] = useState(image);
const [privacyEnabled, setPrivacyEnabled] = React.useState(false);

  console.log("t_description", t_description,name);




  const { register, handleSubmit, control, reset, watch, setValue, formState: { errors } }  = useForm(
  {
    defaultValues: {
      playlistTitle:name,
      playlistDescription: t_description
    }
  }
);


useEffect(()=>{
  setValue('playlistTitle', name);
  setValue('playlistDescription', t_description);

},[name,t_description])

 

// useEffect(()=>{
//   setOldData({
//     "playlistImage": '', // URL or File for prefilled image if available
//     "playlistTitle": "",
//     "playlistDescription": ""
//   })
// },[openEditPlaylistModal])

  const [imgUrl, setImgUrl] = React.useState(image);
  const [selectedImage, setSelectedImage] = React.useState("");

  const isDark = useSelector((state) => state.dark.isDarkValue);
  

  console.log("naming",name,openEditPlaylistModal);


  const handleCloseEditPlaylist = () => {
    // setOldData({
    //   "playlistImage": '', // URL or File for prefilled image if available
    //   "playlistTitle":"",
    //   "playlistDescription": ""
    // })
    setPlaylistName()
    reset()
    setOpenEditPlaylistModal("")
  }

const [imageUrl, setImageUrl] = useState(image);
// const handleImageChange = (event) => {
//   const file = event.target.files[0];
//   if (file) {
//       setImageFile(file);
//       const imageUrl = URL.createObjectURL(file);
//       setImageUrl(imageUrl);
//   }
//   };

const handleImageChange = (event) => {
  const file = event.target.files[0];
  console.log("mmnn",file.name);
  const immgg = file.name

  const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];

  let isValidFormat = false;

  if (immgg) {
    // Validate based on URL extension
    const urlExtension = immgg.split('.').pop().toLowerCase();
    console.log("urlExtension",urlExtension);
    
    isValidFormat = ['png', 'jpeg', 'jpg', 'webp'].includes(urlExtension);
  } else if (immgg) {
    // Validate based on file MIME type
    isValidFormat = allowedFormats.includes(immgg.type);
  }

  if (!isValidFormat) {
    // alert('Invalid image format. Please use PNG, JPEG, JPG, or WEBP.');
    globalToast("Invalid image format. Please use PNG, JPEG, JPG, or WEBP.")
    // setImageFile(image)
    return; // Exit the function if the format is invalid
  }
  
  if (file) {
      setImageFile(file);
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(file);
  }
  };

  // playlistDescription

  const handleEditSubmit = async(data) => {
    console.log('Edited playlist data:', data);
    // Process the edited data (e.g., send to API)

// const imgg = imageUrl?imageUrl:imageFile
 
    const payloadplaylist = {
      is_global:globalId
    }

    console.log("imageUrlaa",imageUrl, ">>>>>",imageFile);
    

    const payload = {
      "id":openEditPlaylistModal,
      "v_name":data?.playlistTitle,
      "t_description":data?.playlistDescription,
      "t_image":imageUrl?imageUrl:imageFile
    }
    // // Process the edited data (e.g., send to API)

    const editPlaylistRes = await handlePostRequest(`edit-playlist`,payload,true,true)
    console.log("editPlaylistRes",editPlaylistRes);
    if(editPlaylistRes?.flag){
      handleCloseEditPlaylist()
      const managePlaylistRes = await handleGetRequest(`${`manage-playlist?type=my_playlists&search_keyword=&sort_type=new`}`,false, false)
      console.log("managePlaylistRes",managePlaylistRes?.data);
      setManagePlaylistData(managePlaylistRes?.data)

      // /playlist/pnwmsrbfrbobsfcwdrphge
        if(title!=="manage-playlist"){

          const platformDetailDataReq = await handlePostRequest(`playlist/${id}`, payloadplaylist, false,true);
          setPlaylistDetailData(platformDetailDataReq?.data)
        }

        reset()
        setValue('playlistTitle', '');
        setValue('playlistDescription', '');
    }
  };


  console.log("imageUrl",imageUrl);
  console.log("imageFile",imageFile);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#312522' : '#F1B942',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
    isDark ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));


  const handleChange = (event,para) => {
    para==="privacy" &&  setPrivacyEnabled(event.target.checked)
};

  return (
    <Dialog  visible={openEditPlaylistModal} className={`dark:!bg-[#151515] p-5 lg:h-[500px] w-[90vw] lg:w-[40vw] h-[32rem]  !bg-white !opacity-1 drop-shadow-xl rounded-xl ${isDark?"dark":""}`}  onHide={() => setOpenEditPlaylistModal("")}>
    <HighlightOffIcon onClick={() => handleCloseEditPlaylist()} className="absolute top-5 right-5 cursor-pointer dark:!text-[#A8A8A8] "/>
    <H220 className="!text-center !text-xl">Edit your playlist</H220>
      <div className=" h-full lg:h-[80%] dark:!bg-[#151515]">
           <form onSubmit={handleSubmit(handleEditSubmit)}>
               {/* <div>
                 <p>Track Cover:</p>
                 <input type="file" id="image" {...register('image')} />
               </div> */}

          <div className='my-3 '>
           <p className='text-sm font-semibold my-2 dark:text-white'>Playlist cover:</p> 
           <div className=" w-28 h-28 relative ">
                <img
                src={imageUrl?imageUrl:imageFile}
                alt="Preview"
                className="w-full h-full object-cover rounded-2xl"
            />
             <div className={`absolute top-[-8px] right-[-8px]  w-8 h-8 border-[3px] border-[#312522] bg-white rounded-full flex justify-center items-center`}>
                    <div className="relative">
                    <input
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="!opacity-0 w-7 absolute top-0 rounded-2xl"
                    />
                
                    <EditOutlinedIcon className="!text-[#312522] "/>
                    </div>
                </div>
                <div>
                </div>
                </div>
                </div>
              
               <div className='my-5'>
                  <p  className='text-sm font-semibold dark:text-white my-2'>Playlist title:</p>
                 <input className={`bg-[#FAFAF8] w-full text-base rounded-3xl py-2 px-2 dark:text-white dark:bg-black  ${errors.playlistTitle?"border-[1px] border-red-600":""}`} type="text" {...register('playlistTitle', { required: true })} />
                 {errors.playlistTitle && <div className='text-left'><span className="text-red-600 text-xs lg:text-sm dark:!text-white">Please enter playlist title</span></div>}
               </div>
               <div className='my-5'>
                 < p  className='text-sm font-semibold dark:text-white my-2'>Description:</p>
                 <input className={`bg-[#FAFAF8] w-full text-base  rounded-3xl py-2 px-2 dark:text-white dark:bg-black ${errors.playlistDescription?"border-[1px] border-red-600":""}`} type="text" {...register('playlistDescription', { required: true })} />
                 {errors.playlistDescription && <div className='text-left'><span className="text-red-600 text-xs lg:text-sm dark:!text-white">Please enter description</span></div>}
               </div>

               <div className="flex justify-start items-center  gap-x-3">
                <div className="dark:text-white">
                  <p className="font-semibold">Private</p>
                </div>
                <div>
                  <AntSwitch  
                  checked={privacyEnabled}
                  onChange={(e)=>handleChange(e,"privacy")} 
                   className="dark:!accent-slate-400"
                  inputProps={{ 'aria-label': 'ant design' }} />
                </div>
                </div>
               <div className='flex justify-center items-center my-3 lg:my-0'>
               <Button1 className="!w-32 !text-center dark:hover:!text-[#F1B942]" type="submit">Save</Button1>
               </div>
             </form>
      </div>
    </Dialog>

  )
}

export default EditPlaylist



