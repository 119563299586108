import { useEffect, useRef, useState } from "react"
import HeadphonesIcon from '@mui/icons-material/Headphones';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import QueueMusicOutlinedIcon from '@mui/icons-material/QueueMusicOutlined';
import ShareTrack from "./ShareTrack";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Button2 from '../GlobalButtonLayout/Button2'
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../../api/api";
import { filterStatus, playerOn, recommendStatus, trackDetail, trackTitleSave } from "../../../../store/trackSlice";
import EditTrack from "./EditTrack";
import DeleteTrackModal from "./DeleteTrackModal";
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import CreatePlaylistModal from "./CreatePlaylistModal";
import Filters from "../Filters/Filters";
import CheckIcon from '@mui/icons-material/Check';
import PauseIcon from '@mui/icons-material/Pause';
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
// import { Checkbox } from 'primereact/checkbox';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button1 from "../GlobalButtonLayout/Button1";
import AddToPlaylist from "./AddToPlaylist";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { handleCountSongs, handleLikeSongs } from "../../../../store/trackLikeSlice";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { handleCurrentSongIndex, handleIsRecommended, handlePlayerStatus } from "../../../../store/playerSlice";
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import InfiniteScroll from "react-infinite-scroll-component";
import { handleCreatePlaylistStatus, handleGeneratePlaylistStatus } from "../../../../store/createPlaylistSlice";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import globalToast from "../../../../hooks/globalToast";
import { useStateHistory } from "@mantine/hooks";


const Tracks = ({setSort,type,setType,setSearchQuery,searchQuery,setIsliked,setSongId,setSongDeleteId,setIsFilterApplied,setFilter,title,selectedplatform,filterId,scope,isLoading,isMusicAnalyzer=false,setStep, isuploadPage=false, setSelectedPlatform, setpage }) => {
    const op = useRef(null);
    const dispatch = useDispatch()
    const location = useLocation()
    const [visible, setVisible] = useState(false);
    const [globalAddPlaylistModal, setGlobalAddPlaylistModal] = useState(false);
    const trackData = useSelector((state) => state.tracks.trackData);
    const likedTracks = useSelector((state) => state.likeSongs.likedSongs);
    const countTracks = useSelector((state) => state.likeSongs.countSongs);
    const create = useSelector((state) => state.createplaylist.isCreatePlaylist);
    const generate = useSelector((state) => state.createplaylist.isGeneratePlaylist);
    const [shareId, setShareId] = useState();
    const [trackId, setTrackId] = useState();
    const [sortModal, setSortModal] = useState(false);
    const [createPlaylistModal, setCreatePlaylistModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    // const [create, setCreate] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const { handleGetRequest, handlePostRequest } = useApi();
    const [editData, setEditData] = useState()
    const isPlayer = useSelector((state) => state.tracks.isPlayer);
    const [createPlaylistCode, setCreatePlaylistCode] = useState("");
    const [playlistId, setPlaylistId] = useState("");
    const [selectedFilterData, setSelectedFilterData] = useState([]);
    const searchglobalId = location?.search
    const globalId = searchglobalId.includes("globalid=true");

    console.log("isMusicAnalyzer",generate);

    const [searchExtend,setSearchExtend] = useState(false)

    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setGlobalAddPlaylistModal(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

//   useEffect(()=>{
//             // console.log("sdfsfd",op);
//             dispatch(filterStatus(!op.current?false:true))
//         },[op])




  useEffect(() => {
    // console.log("sdfa",trackData.filter((track) => track?.is_liked).map((track) => track?.id))
    // setLikedTracks(trackData.filter((track) => track?.is_liked).map((track) => track?.id))play_count
    dispatch(handleLikeSongs(trackData?.filter((track) => track?.is_liked)?.map((track) => track?.id)))
    // listen count working
    dispatch(
        handleCountSongs(
          trackData?.map((track) => ({
            play_count: track?.play_count,
            id: track?.id,
          }))
        )
      );

  }, [trackData]);

  const updateLikeStatus = async (trackId, isLiked) => {
        const trackLikeRes = await handleGetRequest(`song-favourite?id=${trackId}&type=${isLiked?"Like":"Dislike"}`, true,false);

        // const trackSongsRes = await handleGetRequest(`${`track?page=1&limit=1000&type=${type}&sort_type=&search_keyword=&e_platform_name=${selectedplatform}`}`,false,false)
        // localStorage.setItem('TrackData', JSON.stringify(trackSongsRes?.data?.data))
        // dispatch(trackDetail(trackSongsRes?.data?.data))
};

  const handleLike = async(trackId) => {
    const updatedLikedTracks = likedTracks?.includes(trackId)
        ? likedTracks.filter((id) => id !== trackId)
        : [...likedTracks, trackId];
        dispatch(handleLikeSongs(updatedLikedTracks))
    // setLikedTracks(updatedLikedTracks);
   
    // Send API request to update like status on the server
    updateLikeStatus(trackId, updatedLikedTracks?.includes(trackId))
};

useEffect(()=>{
    console.log("countTracks",countTracks);

},[countTracks])



  useEffect(()=>{
    createPlaylistCode? setVisible(true) : setVisible(false)
  },[createPlaylistCode])

    console.log("isplayerrrr",isPlayer);


    const [selectedPlaylist, setSelectedPlaylist] = useState(null);

    const toggleDropdown = (id) => {
        setOpenDropdownId(openDropdownId === id ? null : id)
        setCreatePlaylistModal(false)
        const editTrack = trackData?.filter((item)=>item.id===id)
        setEditData(editTrack[0])
    };

    console.log("editData",editData);

    const navigate = useNavigate()

    // useEffect(() => {
    //     console.log("newUrl",location);
    //     handleClose()
    //     setShareId()
    //   }, [location]);

    const handleClickOpen = (id) => {

        handleOpen()
        setShareId(id)
        const newUrl = `${location.pathname}?${id}`;
         window.history.pushState({}, '', newUrl);

    };

 
 
   useEffect(() => {
     const handlePopState = (event) => {
    //    callback(event);
       handleClose()
        setShareId()
        dispatch(filterStatus(false))
        // op.current.toggle(false)
     };
 
     window.addEventListener('popstate', handlePopState);
 
     return () => {
       window.removeEventListener('popstate', handlePopState);
     };
   }, [])
 


    const filterRef = useRef(null);
    const [isFilter, setIsFilter] = useState(false);
    const [selectedFilterName, setSelectedFilterName] = useState([]);
    const [filterData, setFilterData] = useState([]);
    

    useEffect(() => {
        const ids = trackData?.length>0? trackData?.map(song => song?.id):[];
        console.log("songIds",ids);
        (async () => {
            const filterPlayload = {
                song_ids:ids
            }
          const filterDataRes = await handlePostRequest(`filters-new`,filterPlayload, false, false);
          console.log("filterDataRes", filterDataRes);
          setFilterData(filterDataRes)
        })();
      }, [trackData]);

      const [playlistData, setPlaylistData] = useState();


      const getPlaylistData = async(id,platform) => {
        setTrackId(id)
        const playlistRes = await handleGetRequest(`${`playlist-data?e_platform_name=default&type=all&search_keyword=${searchQuery}&sort_type=new`}`,false,false)
        setPlaylistData(playlistRes?.data)
        const newUrl = `${location.pathname}?${Date.now()}`;
        window.history.pushState({}, '', newUrl);
      }


    console.log("playlistData",playlistData);

    //   useEffect(() => {
    //   }, [selectedFilterData]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest(".sort-modal") && !event.target.closest(".sort-icon")) {
                setSortModal(false);
            }
            if (!event.target.closest(".threeDots-modal") && !event.target.closest(".threeDots-icon")) {
                setOpenDropdownId(null);
            }
        };

    document.addEventListener("click", handleOutsideClick);

    return () => {
        document.removeEventListener("click", handleOutsideClick);
    };

    }, []);

    const handleSort = (sort) => {
        setpage(0)
        setSort(sort);
        setSortModal(false);
    };
    
    const handlePlay = async (index,id,title) => {
        dispatch(playerOn(index))
        dispatch(trackTitleSave(title))
        dispatch(handleCountSongs(countTracks.map(item =>
            item.id === id ? { ...item, play_count: item.play_count + 1 } : item
        )))
       
        const listenCountRes = await handlePostRequest(`${`song-listen-count/${id}`}`,false, false)
        // setListenCount(listenCountRes?.data)
      
        // dispatch(recommendStatus(true))
        // setTimeout(() => {
        //     dispatch(recommendStatus(true));
        // }, 1000);
    }

    const [openEditTrackModal, setOpenEditTrackModal] = useState(false);

    const [trackInfo,setTrackInfo] = useState({})

    const handleClickOpenEditTrackModal = (Info) => {
        setOpenEditTrackModal(true);
        console.log("trackInfo",Info);
        setTrackInfo(Info)
        const newUrl = `${location.pathname}?${Date.now()}`;
        window.history.pushState({}, '', newUrl);
      };

      useEffect(() => {
        const handlePopState = (event) => {
       //    callback(event);
        //   setNotificationPopUpMobile(false)
          setOpenEditTrackModal(false);

          
        };
    
        window.addEventListener('popstate', handlePopState);
    
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
      }, [])

      const [openCreatePlaylistTrackModal, setOpenCreatePlaylistTrackModal] = useState(false);
      const [createPlaylistId, setCreatePlaylistId] = useState();

      const handleClickOpenCreatePlaylistTrackModal = (id) => {
        console.log("tumhh");
        
        if(selectedRows.length<1 && !id){
            // toast.error("Select at least 1 track");
            globalToast("Select at least 1 track",true)
            return;
        }
        setOpenCreatePlaylistTrackModal(true);
        const newUrl = `${location.pathname}?${Date.now()}`;
        window.history.pushState({}, '', newUrl);
        setCreatePlaylistId([id])
        };

        useEffect(() => {
            const handlePopState = (event) => {
           //    callback(event);
           setOpenCreatePlaylistTrackModal(false)
           setCreatePlaylistModal(false)
              
            };
        
            window.addEventListener('popstate', handlePopState);
        
            return () => {
              window.removeEventListener('popstate', handlePopState);
            };
          }, [])

    const [openDeleteTrackModal, setOpenDeleteTrackModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    const handleClickOpenDeleteTrackModal = (id) => {
        setOpenDeleteTrackModal(true);
        setDeleteId(id)
      };

      const [selectAll, setSelectAll] = useState(false);
      const [selectedRows, setSelectedRows] = useState([]);

      const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            const allIds = trackData.map(item => item.id);
            setSelectedRows(allIds);
        }
        setSelectAll(!selectAll);
    };

    const handleRowSelect = (id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }

        setSelectedRows(newSelected);

        if (newSelected.length === trackData.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    
    };

    const [filterLength,setFilterLength] = useState(0)

    useEffect(()=>{
        let totalFilters = 0;
        for (const key in selectedFilterData) {
          if (Array.isArray(selectedFilterData[key])) {
            totalFilters += selectedFilterData[key].length;
          }
        }
        // return totalFilters;
        setFilterLength(totalFilters)
    },[selectedFilterData])

    useEffect(()=>{
        setIsFilterApplied(filterLength)
        setFilter(selectedFilterData)
    },[filterLength])
    

    console.log("selectedFilterData", filterLength);


    console.log("trackId",trackId);
    const handleAddToPlaylist = async (playlistCode) => {



        if(!playlistCode){
            toast.custom((t) => (
                <div
                  className={`${
                    t.visible ? 'animate-enter' : 'animate-leave'
                  } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 `}
                >
                  <div className="flex-1 w-0 p-4">
                    <div className="flex justify-start items-center">
                      <div className="flex-shrink-0 pt-0.5">
                        <img
                          className="h-10 w-10 rounded-full"
                          src="/1922.png"
                          alt=""
                        />
                      </div>
                      <div className="ml-3 flex-1">
                        {/* <p className="text-sm font-medium text-white">
                          Emilia Gates
                        </p> */}
                        <p className="mt-1 text-sm text-black  flex justify-start items-center font-semibold">
                          Please select file
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            return
        }
        const payload = {
            "track_id":trackId,
            "v_code":playlistCode,
            
        }
        const addPlaylistRes = await handlePostRequest(`${`add-song-to-playlist`}`,payload,true, true)
        console.log("addPlaylistRes",addPlaylistRes);
        setCreatePlaylistModal(false)
       
    }

        const [seaQuery, setSeaQuery] = useState('');
        const handleSearchChange = (event) => {
            setSeaQuery(event.target.value);
        };

        const filteredPlaylists = playlistData?.filter(playlist =>
            playlist.v_name.toLowerCase()?.includes(seaQuery.toLowerCase())
        );
        const [genresDP,setGenresDP] = useState()

        useEffect(() => {
            (async () => {
                
                    const genresPlayload = {
                        type:"genres",
                        current_page: 1,
                        per_page:12
                    }
                  const genresStepperRes = await handlePostRequest(`users/music-preferences/get`,genresPlayload, false, true);
                  if(genresStepperRes?.flag){

                    console.log("genresStepperRes",genresStepperRes?.data?.data);
                    setGenresDP(genresStepperRes?.data?.data)
                  }
            })();
          }, []);

          console.log("openDropdownId",openDropdownId);

        //   const playSong = (songData,currentSongIndex,id) => {
        //     dispatch(handleIsRecommended(false))
        //     localStorage.setItem('playingCurrentSong',  JSON.stringify(songData))
        //     dispatch(handlePlayerStatus(songData?.e_platform));
        //     dispatch(handleCurrentSongIndex(currentSongIndex));
        //     dispatch(handleCountSongs(countTracks.map(item =>
        //         item.id === id ? { ...item, play_count: item.play_count + 1 } : item
        //     )))
        //     const listenCountRes = await handlePostRequest(`${`song-listen-count/${songs[currentSongIndex]?.id}`}`,false, false)
        //   }

        // const playSong = async (songData, currentSongIndex, id) => {
        //     dispatch(handleIsRecommended(false));
        //     console.log("currentSongIndex",currentSongIndex);
        //     localStorage.setItem('playingCurrentSong', JSON.stringify(songData));
        //     dispatch(handlePlayerStatus(songData?.e_platform));
        //     dispatch(handleCurrentSongIndex(currentSongIndex));
        //     dispatch(handleCountSongs(countTracks.map(item =>
        //         item.id === id ? { ...item, play_count: item.play_count + 1 } : item
        //     )));
        //     const listenCountRes = await handlePostRequest(`${`song-listen-count/${id}`}`, false, false);
        // };

        const playSong = async (songData, currentSongIndex, id) => {
            // dispatch(handleCurrentSongIndex(-1));
            // setTimeout(() => {
                countTracks?.map((item)=>{
                console.log("dsfsf",id,item?.id,item?.play_count,id===item?.id?{ ...item, play_count:  parseInt(item?.play_count, 10) || 0 + 1 } : item);    
                })
                
                dispatch(handleCountSongs(countTracks.map(item =>
                    item.id === id ? { ...item, play_count: item.play_count + 1 } : item
                )));
                
                const listenCountRes = await handlePostRequest(`song-listen-count/${id}`, false, false);
                const songUrl = JSON.parse(localStorage.getItem('playingCurrentSong'));


                if(songUrl?.id===id){
                console.log("songUrlcurrentSongIndex", songUrl);

                // dispatch(handleCurrentSongIndex(-1));
                dispatch(handlePlayerStatus(false));

                setTimeout(() => {
                    dispatch(handleIsRecommended(false));
                    console.log("currentSongIndex", currentSongIndex);
                    localStorage.setItem('playingCurrentSong', JSON.stringify(songData));
                    dispatch(handlePlayerStatus(songData?.e_platform));

                }, 1000);
                return
                }

            dispatch(handleIsRecommended(false));
            console.log("currentSongIndex", currentSongIndex);
            localStorage.setItem('playingCurrentSong', JSON.stringify(songData));
            dispatch(handlePlayerStatus(songData?.e_platform));

            // }, 100);

            
            // Update currentSongIndex to -1 or a temporary value for half a second
            if (currentSongIndex === 0) {
                dispatch(handleCurrentSongIndex(-1));
                setTimeout(() => {
                    dispatch(handleCurrentSongIndex(1));
                }, 100);
            } else {
                const tempIndex = currentSongIndex * -1; // Invert the sign for temporary value
                // dispatch(handleCurrentSongIndex(tempIndex));
                    dispatch(handleCurrentSongIndex(currentSongIndex));

                // setTimeout(() => {
                //     dispatch(handleCurrentSongIndex(currentSongIndex));
                // }, 100);
            }
            
            
        };

        const generateref = useRef(false)

        useEffect(()=>{

            if (!generateref.current) {
                generateref.current = true; // Set ref to true after initial render
                return; // Prevents the rest of the effect from running on the initial render
              }

              if(selectedRows.length<1 ){
                // toast.error("Select at least 1 track");
                // globalToast("Select at least 1 track",true)
                dispatch(handleGeneratePlaylistStatus(false))
               
            }
            console.log("sdfsgenerate");
            if(generate){

                handleClickOpenCreatePlaylistTrackModal()
            }
        },[generate])


        console.log("openEditTrackModal",selectedplatform);
    

        const handleFilterMobile = (e) => {     
            console.log("e1",e);
            
            dispatch(filterStatus(true)); 
            op.current.toggle(e)
            const isMobileView = window.innerWidth < 768;
            if(isMobileView){
                //  only in mobile view
                const newUrl = `${location.pathname}?`;
                window.history.pushState({}, '', newUrl);
            }

        }

        const isDark = useSelector((state) => state.dark.isDarkValue);

        const handleType = (type) => {
            setpage(0)
            setType(type)
        }

    return(
        <div>
            <div className={`card flex justify-content-center  dark:!bg-[#151515] first-letter:first-line:`}>
            {/* <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} /> */}
            <Dialog  visible={visible} className={`dark:bg-[#151515]  lg:h-56 w-[90vw] lg:w-[40vw] h-48 text-center !bg-white !opacity-1 drop-shadow-xl rounded-2xl ${isDark?"dark":""}`}  onHide={() => setVisible(false)}>
                <HighlightOffIcon onClick={() => setVisible(false)} className="absolute top-5 right-5 cursor-pointer dark:!text-[#A8A8A8] "/>
                <div className="flex flex-col justify-center h-full dark:!bg-[#151515]">
                    <div className="mt-5 lg:mt-0">
                        <p className="m-0 lg:text-2xl text-base font-semibold dark:text-[#FFFFFF]">
                        Playlist created , enjoy your music !
                        </p>
                        <div className="flex justify-center items-center gap-4 my-4">
                            <Link to={`/collection/playlists/${createPlaylistCode}?id=${playlistId}`} onClick={()=>setVisible(false)}><Button2 className="!text-xs dark:text-[#F3C25A] lg:!text-sm !text-center">This playlist</Button2></Link>
                            <Link to="/collection/playlists"><Button2 className="!text-xs dark:text-[#F3C25A] lg:!text-sm !text-center">All playlists</Button2></Link>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
            {createPlaylistModal && <AddToPlaylist setCreatePlaylistModal={setCreatePlaylistModal} trackId={trackId} playlistData={playlistData} handleAddToPlaylist={handleAddToPlaylist} handleClickOpenCreatePlaylistTrackModal={handleClickOpenCreatePlaylistTrackModal}/>}
             <ShareTrack  id={shareId} location={`https://curioushit-frontend.nevinainfotech.in/playlist/${shareId}`} handleOpen={handleOpen} handleClose={handleClose} open={open} setShareId={setShareId}/>
{/* 
            { editData?.i_year_display && genresDP &&<EditTrack openEditTrackModal={openEditTrackModal} setOpenEditTrackModal={setOpenEditTrackModal} trackCover={editData?.t_image_path} trackTitle={editData?.t_title} year={editData?.i_year_display} albumName={editData?.album_name} id={editData?.id} genreName={editData?.t_genres_display} artistName={editData?.artist_name} setEditData={setEditData} genresDP={genresDP}/>} */}
           { trackInfo?.t_title && <EditTrack trackInfo={trackInfo} openEditTrackModal={openEditTrackModal}  setOpenEditTrackModal={setOpenEditTrackModal} setTrackInfo={setTrackInfo} setSelectedPlatform={setSelectedPlatform} selectedplatform={selectedplatform}/>}
             
             <DeleteTrackModal setSongDeleteId={setSongDeleteId} deleteId={deleteId}  openDeleteTrackModal={openDeleteTrackModal} setOpenDeleteTrackModal={setOpenDeleteTrackModal}/>

            <CreatePlaylistModal setSelectAll={setSelectAll} openCreatePlaylistTrackModal={openCreatePlaylistTrackModal} setOpenCreatePlaylistTrackModal={setOpenCreatePlaylistTrackModal} id={selectedRows.length>0?selectedRows:createPlaylistId} setSelectedRows={setSelectedRows} setCreatePlaylistCode={setCreatePlaylistCode} createPlaylistCode={createPlaylistCode} setCreatePlaylistId={setPlaylistId}/>

            {/* {!isuploadPage &&<div className={`flex ${globalId?"justify-end":"justify-between"} gap-x-4 items-center my-4`}> */}
            {!isuploadPage &&<div className={`flex ${scope==="globalSearch" && "hidden"} justify-between gap-x-4 items-center my-4`}>

                 <div className="px-2 flex flex-wrap gap-3 justify-start gap-x-4 items-center">
                <button className={`w-16 lg:w-20 py-1 hover:bg-[#F1B942] hover:text-white rounded-[30px] ${type==="all"?"bg-[#F1B942] text-white text-xs":"bg-white dark:bg-[#151515]  dark:text-white text-[#312522] !text-xs"}`} onClick={()=>handleType("all")}>All</button>
                <button className={`w-16 lg:w-20 py-1 hover:bg-[#F1B942] hover:text-white rounded-[30px] ${type==="liked"?"bg-[#F1B942] text-white text-xs":"bg-white dark:bg-[#151515]  dark:text-white text-[#312522] !text-xs"}`} onClick={()=>handleType("liked")}>Liked</button>
                <button className={`w-16 lg:w-20 py-1 hover:bg-[#F1B942] hover:text-white rounded-[30px] ${type==="shared"?"bg-[#F1B942] text-white text-xs":"bg-white dark:bg-[#151515]  dark:text-white text-[#312522] !text-xs"}`} onClick={()=>handleType("shared")}>Shared</button>
                <div className="relative inline-block">
              
                {/* { create && <button className={`w-16 lg:w-20 hover:text-white py-1 hover:bg-[#F1B942] rounded-[30px] ${type==="khkjg"?"bg-[#F1B942] text-white text-xs":"bg-white dark:bg-[#151515]  dark:text-white  text-[#312522] text-xs"}`} onClick={(e) =>handleFilterMobile(e)} >{`${filterLength>0?"":"Filter"}`}{filterLength>0?`${filterLength} selected`:""} </button>} */}

                { create && <button className={`w-16 lg:w-20 hover:text-white py-1 hover:bg-[#F1B942] rounded-[30px] ${type==="khkjg"?"bg-[#F1B942] text-white text-xs":"bg-white dark:bg-[#151515]  dark:text-white  text-[#312522] text-xs"}`} onClick={() =>setIsFilterOpen(pre=>!pre)} >{`${filterLength>0?"":"Filter"}`}{filterLength>0?`${filterLength} selected`:""} </button>}

               {isFilterOpen && <div className={`fixed lg:relative  z-50 top-0 w-full h-full bg-white`}>
                <Filters filterList={filterData} filterId={filterId} scope={scope} setSelectedFilterData={setSelectedFilterData} selectedFilterData={selectedFilterData} selectedFilterName={selectedFilterName} setSelectedFilterName={setSelectedFilterName} filterRef={filterRef} setIsFilter={setIsFilter} selectedplatform={selectedplatform} op={op} setIsFilterOpen={setIsFilterOpen}/>
                </div>}
               
                {/* <OverlayPanel ref={op} >
                
                </OverlayPanel> */}
                </div>
               
                <div className="hidden lg:flex justify-start items-center gap-x-3 relative">
                    <div className={`relative  ${searchExtend?"w-48":"w-0"} duration-700 `}>
                    <input
                        type="text"
                        placeholder="Search..."
                        className={`bg-white ${searchExtend?"":"px-7"} rounded-[50px] py-2 pl-2 h-full w-full dark:bg-[#151515] dark:border-[#151515]  dark:text-white  focus:outline-none focus:border-[#F1B942]  border-[1px] text-xs transition duration-300 `}
                        value={searchQuery}
                        onChange={(e) =>{ setSearchQuery(e.target.value); }}
                    />
                    <SearchOutlinedIcon onClick={()=>setSearchExtend(pre=>!pre)} className={`absolute dark:!text-[#DDD1CE] top-1/2 transform -translate-y-1/2 ${searchExtend?"right-3":"-right-8"}  cursor-pointer`}/>
                    </div>
                    <div className={`cursor-pointer sort-icon  ${searchExtend?"":"ml-10"}`}>
                        <img className="dark:filter dark:invert"  onClick={()=>setSortModal(pre=>!pre)} src="/images/TrackTable/sortby.svg" alt=""  />
                    </div>
                    <div  className={`absolute sort-modal top-[100%] right-[0%]  z-[9999] min-w-36 text-sm bg-white rounded-lg drop-shadow-lg p-3 ${!sortModal?"hidden":""} dark:border-[1px] dark:border-[#343434] dark:bg-[#151515] dark:text-[#DDD1CE]`}>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942] text-xs" onClick={()=>handleSort("new")}>New</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942] text-xs" onClick={()=>handleSort("popular")}>Popular</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942] text-xs" onClick={()=>handleSort("listened")}>Listened</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942] text-xs" onClick={()=>handleSort("favorite")}>Favorites</p>
                    </div>
                </div>
                </div>
                {/* {(scope!=="globalSearch" && !globalId) && <div className="px-2 flex flex-wrap gap-3 justify-start gap-x-4 items-center">
                <button className={`w-16 lg:w-20 py-1 hover:bg-[#F1B942] hover:text-white rounded-[30px] ${type==="all"?"bg-[#F1B942] text-white text-xs":"bg-white dark:bg-[#151515]  dark:text-white text-[#312522] !text-xs"}`} onClick={()=>setType("all")}>All</button>
                <button className={`w-16 lg:w-20 py-1 hover:bg-[#F1B942] hover:text-white rounded-[30px] ${type==="liked"?"bg-[#F1B942] text-white text-xs":"bg-white dark:bg-[#151515]  dark:text-white text-[#312522] !text-xs"}`} onClick={()=>setType("liked")}>Liked</button>
                <button className={`w-16 lg:w-20 py-1 hover:bg-[#F1B942] hover:text-white rounded-[30px] ${type==="shared"?"bg-[#F1B942] text-white text-xs":"bg-white dark:bg-[#151515]  dark:text-white text-[#312522] !text-xs"}`} onClick={()=>setType("shared")}>Shared</button>
                <div className="relative inline-block">
              
                { create && <button className={`w-16 lg:w-20 hover:text-white py-1 hover:bg-[#F1B942] rounded-[30px] ${type==="khkjg"?"bg-[#F1B942] text-white text-xs":"bg-white dark:bg-[#151515]  dark:text-white  text-[#312522] text-xs"}`} onClick={(e) =>{ op.current.toggle(e); dispatch(filterStatus(true)) }} >Filter {filterLength>0?`${filterLength} selected`:""} </button>}

                <OverlayPanel ref={op} >
                <Filters filterList={filterData} filterId={filterId} scope={scope} setSelectedFilterData={setSelectedFilterData} selectedFilterData={selectedFilterData} selectedFilterName={selectedFilterName} setSelectedFilterName={setSelectedFilterName} filterRef={filterRef} setIsFilter={setIsFilter} selectedplatform={selectedplatform} op={op}/>
                </OverlayPanel>
                </div>
               
                <div className="hidden lg:flex justify-start items-center gap-x-3 relative">
                    <div className={`relative  ${searchExtend?"w-48":"w-0"} duration-700 `}>
                    <input
                        type="text"
                        placeholder="Search..."
                        className={`bg-white ${searchExtend?"":"px-7"} rounded-[50px] py-2 pl-2 h-full w-full dark:bg-[#151515] dark:border-[#151515]  dark:text-white  focus:outline-none focus:border-[#F1B942]  border-[1px] text-xs transition duration-300 `}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <SearchOutlinedIcon onClick={()=>setSearchExtend(pre=>!pre)} className={`absolute dark:!text-[#DDD1CE] top-1/2 transform -translate-y-1/2 ${searchExtend?"right-3":"-right-8"}  cursor-pointer`}/>
                    </div>
                    <div className={`cursor-pointer sort-icon  ${searchExtend?"":"ml-10"}`}>
                        <img className="dark:filter dark:invert"  onClick={()=>setSortModal(pre=>!pre)} src="/images/TrackTable/sortby.svg" alt=""  />
                    </div>
                    <div  className={`absolute sort-modal top-[100%] right-[0%]  z-[9999] w-full text-sm bg-white rounded-lg drop-shadow-lg p-3 ${!sortModal?"hidden":""} dark:border-[1px] dark:border-[#343434] dark:bg-[#151515] dark:text-[#DDD1CE]`}>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("new")}>New</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("popular")}>Popular</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("listened")}>Listened</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("favorite")}>Favorites</p>
                    </div>
                </div>
                </div>} */}
               { scope!=="globalSearch" && <div>
                {!create   ? isMusicAnalyzer?
                <div className="hidden lg:block" onClick={()=>setStep(2)} >
                    <Button2 className="!w-full  !px-7 !bg-black !text-white !border-black hover:!text-black hover:!bg-white hover:!border-black  dark:!border-[1px] dark:hover:!bg-[#F3C25A] dark:hover:!text-white dark:!text-[#F3C25A] dark:!border-[#F3C25A] dark:bg-[#0D0D0D]">Upload</Button2>
                </div>: <div className="hidden lg:block" onClick={()=> dispatch(handleCreatePlaylistStatus(true))
}>
                    <Button2 className="!w-full  !px-7 !bg-black !text-white !border-black hover:!text-black hover:!bg-white hover:!border-black  dark:!border-[1px] dark:hover:!bg-[#F3C25A] dark:hover:!text-white dark:!text-[#F3C25A] dark:!border-[#F3C25A] dark:bg-[#0D0D0D]">Generate playlist</Button2>
                </div> :
                <div className="hidden lg:block" onClick={()=>handleClickOpenCreatePlaylistTrackModal()}>
                    <Button2 className="!w-full !px-7 !bg-black !text-white !border-black hover:!text-black hover:!bg-white hover:!border-black dark:!border-[#DDD1CE] dark:!text-[#DDD1CE] dark:hover:!bg-[#F3C25A] dark:hover:!text-white">Generate</Button2>
                </div>}
                </div>}
            </div>}

            <div>

            {!isuploadPage && <div className={`bg-white lg:drop-shadow-xl lg:hidden rounded-tl-xl  rounded-tr-xl py-3 flex justify-between items-center dark:bg-[#151515] ${scope==="globalSearch" && "hidden"}`}>
                {/* <div className={`relative w-full flex ${globalId?"justify-end":"justify-between"}  items-center gap-x-1  lg:w-auto`}> */}
                <div className={`relative w-full flex justify-between  items-center gap-x-1  lg:w-auto`}>
               {/* {( scope!=="globalSearch" && !globalId ) && <div className="relative mx-2 w-[36%]  lg:w-auto">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="bg-[#fafaf8] rounded-[50px] pl-2 pr-9 py-2 w-full lg:w-auto  focus:outline-none focus:border-[#F1B942] border-[1px] transition duration-300 dark:bg-[#151515] dark:border-[#151515]  dark:text-white "
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <SearchOutlinedIcon className="absolute top-1/2 dark:!text-[#DDD1CE] transform -translate-y-1/2 right-3"/>
                </div>} */}

                <div className="relative mx-2 w-[36%]  lg:w-auto">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="bg-[#fafaf8] rounded-[50px] pl-2 pr-9 py-2 w-full lg:w-auto  focus:outline-none focus:border-[#F1B942] border-[1px] transition duration-300 dark:bg-[#151515] dark:border-[#151515]  dark:text-white "
                        value={searchQuery}
                        onChange={(e) => {setSearchQuery(e.target.value);}}
                    />
                    <SearchOutlinedIcon className="absolute top-1/2 dark:!text-[#DDD1CE] transform -translate-y-1/2 right-3"/>
                </div>
               {/* { ( scope!=="globalSearch" && !globalId ) &&<div className="relative lg:w-auto ">
                    <div className="cursor-pointer  sort-icon">
                        <img  className="dark:filter dark:invert" onClick={()=>setSortModal(pre=>!pre)} src="/images/TrackTable/sortby.svg" alt=""  />
                    </div>
                    {sortModal && <div   className={`absolute sort-modal top-[220%] right-[50%]  z-50 text-sm bg-white rounded-lg drop-shadow-2xl pl-4 pr-20 py-3 dark:border-[1px] dark:border-[#343434] dark:bg-[#151515] dark:text-[#DDD1CE]`}>
                        <div className="relative">
                        <div className="absolute -top-[37px] -right-[82px]">
                            <img className="dark:filter dark:invert" src="/images/Triangle.png" alt="" />
                        </div>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("new")}>New</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("popular")}>Popular</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("listened")}>Listened</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("favorite")}>Favorites</p>
                        </div>
                    </div>}
                </div>} */}

               <div className="relative lg:w-auto ">
                    <div className="cursor-pointer  sort-icon">
                        <img  className="dark:filter dark:invert" onClick={()=>setSortModal(pre=>!pre)} src="/images/TrackTable/sortby.svg" alt=""  />
                    </div>
                    {sortModal && <div   className={`absolute sort-modal top-[220%] right-[50%]  z-50 text-sm bg-white rounded-lg drop-shadow-2xl pl-4 pr-20 py-3 dark:border-[1px] dark:border-[#343434] dark:bg-[#151515] dark:text-[#DDD1CE]`}>
                        <div className="relative">
                        <div className="absolute -top-[37px] -right-[82px]">
                            <img className="dark:filter dark:invert" src="/images/Triangle.png" alt="" />
                        </div>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("new")}>New</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("popular")}>Popular</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("listened")}>Listened</p>
                        <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleSort("favorite")}>Favorites</p>
                        </div>
                    </div>}
                </div>

                {( scope!=="globalSearch"  )&&<div>
                {!create  ?
                <div className="block lg:hidden  mx-1" onClick={()=> dispatch(handleCreatePlaylistStatus(true))
                }>
                    <Button2 className="!w-full !px-2 !bg-black !text-white !border-black hover:!text-black hover:!bg-white hover:!border-black">Generate playlist</Button2>
                </div> :
                <div className="block lg:hidden mx-1" onClick={()=>handleClickOpenCreatePlaylistTrackModal()}>
                    <Button2 className="!w-full !px-2 !bg-black !text-white !border-black hover:!text-black hover:!bg-white hover:!border-black ">Generate</Button2>
                </div>}
                </div>}
                </div>
            </div>}

            <div className={`bg-white dark:bg-[#151515] mt-10 rounded-xl p-1 ${sortModal?"lg:drop-shadow-sm":"drop-shadow-xl"} ${trackData?.length>0?"pulse-animate ":"h-28"}`}>
                
            <table className={`table-fixed w-full  mb-3 lg:rounded-xl relative rounded-xl ${isLoading? "pulse-animate" : ""}`}>
            <thead className={`bg-white dark:bg-[#151515] rounded-xl ${isLoading?"pulse-animate":""}`}>
                <tr className="capitalize  lg:text-center text-[#A1A1A1] text-sm font-semibold " >
                {create &&<th className="px-2 py-3 lg:rounded-tl-xl w-[20%] lg:w-auto">
                    {/* <input type="checkbox" onChange={handleSelectAll} checked={selectAll} className="" /> */}
                    <div className="inline-flex items-center">
                            <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="customCheck">
                                <input
                                type="checkbox"
                                className="peer hidden"
                                id="customCheck"
                                onChange={handleSelectAll}
                                checked={selectAll}
                                />
                                {/* <span class="absolute peer-checked:!text-white bg-white  pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:bg-[#F1B942]"> */}
                                <CheckIcon className="text-white dark:text-[#151515] peer-checked:!text-white  border-[1px] border-black peer-checked:bg-[#F1B942] !text-base !rounded-sm  absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 dark:border-white"/>
                                {/* </span> */}
                            </label>
                            </div>
                </th>}
                <th className="px-2 py-3 text-start lg:text-center lg:rounded-tl-xl font-semibold capitalize ">Title</th>
                {title!=="Albums" &&<th className="px-2 py-3 hidden lg:table-cell font-semibold capitalize ">{isMusicAnalyzer?"Status":"Source"}</th>}
                <th className="px-2 py-3 hidden lg:table-cell font-semibold capitalize ">{isMusicAnalyzer?"View File":"Genres"}</th>
                {!isuploadPage && <th className="px-2 py-3 hidden lg:table-cell font-semibold">Listened</th>}
                <th className="px-2 py-3 hidden lg:table-cell font-semibold">Duration</th>
                {!create && <th className="px-2 py-3  lg:table-cell text-end lg:rounded-tr-xl lg:text-center font-semibold">Actions</th>}
                </tr>
            </thead>
            <tbody className={`bg-white dark:bg-[#151515] lg:rounded-b-xl`}>
                {trackData?.length>0 ?
                    trackData?.map((items,index)=> {
                        const isSelected = selectedRows?.includes(items.id);
                       return(
                           <tr key={items?.id} className="rounded-xl" >
                               {create && <td className="">
                                   <div className="flex justify-center items-center">

                            <div class="inline-flex items-center">
                            <label class="relative flex items-center p-1 rounded-full cursor-pointer" htmlFor={items.id}>
                                <input
                                type="checkbox"
                                className="peer hidden"
                                id={items.id}
                                onChange={() => handleRowSelect(items.id)}
                                checked={isSelected}
                                />
                                <CheckIcon className="text-white dark:text-[#151515] peer-checked:!text-white border-[1px] border-black dark:border-white  peer-checked:bg-[#F1B942] !text-base !rounded-sm  absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4"/>
                            </label>
                            </div>
                            </div>
                            </td>}
                               <td onClick={()=> playSong(items,index,items?.id)} className="!text-left py-3 flex justify-start items-center gap-x-2 px-3">
                                   <div className="w-[50px] h-[50px]">
                                   <img  src={`${items?.t_image_path}`} alt='img' className='object-cover h-full w-full  rounded-md cursor-pointer' />
                                   </div>
   
                                   {/* <div className="w-[50%] overflow-hidden cursor-pointer" onClick={()=>{handlePlay(index,items?.id,items?.t_title);dispatch(recommendStatus(false))}}> */}
                                   <div className="w-[50%] overflow-hidden cursor-pointer">
                                        <Tooltip title={items?.t_title}>
                                       <p className="text-[#312522]  text-xs font-semibold mb-1 lg:mb-2 line-clamp-1 text-wrap dark:text-[#DDD1CE] capitalize">{items?.t_title}</p>
                                       </Tooltip>
                                       <Tooltip title={items?.artistlist}>
                                       <p className="text-[#B7B7B7] capitalize  truncate text-xs dark:text-[#DDD1CE]">{items?.artistlist}</p>
                                       </Tooltip>
                                   </div>
                               </td>
                               {(title!=="Albums" && !isMusicAnalyzer )&&<td className="!text-left w-[39%] hidden lg:table-cell">
                                   <div>
                                       {/* <p className="text-[#312522] line-clamp-1 text-center text-xs font-normal mb-1 lg:mb-2 dark:text-[#DDD1CE]">{items?.albumlist}</p> */}
                                       <p className="text-[#312522] line-clamp-1 text-center text-xs font-normal mb-1 lg:mb-2 dark:text-[#DDD1CE]">{items?.e_platform==="Default"?"Upload":"Sync"}</p>

                                   </div>
                               </td>}
                               {(isMusicAnalyzer)&&<td className="!text-left w-[39%] hidden lg:table-cell">
                                   <div>
                                       <p className="text-[#312522] line-clamp-1 text-center text-xs font-normal mb-1 lg:mb-2 dark:text-[#DDD1CE]">Processed</p>
                                   </div>
                               </td>}
                               <td className="!text-left w-[39%]  hidden lg:table-cell">
                                   {(items?.t_genres_display && !isMusicAnalyzer) &&<div>
                                       <p className="text-[#3871E4] px-2 bg-white border-[1px] border-[#3871E4] dark:bg-[#1A1A1A] dark:text-[#DDD1CE] text-center w-2/4 mx-auto rounded-[30px] py-1 text-xs font-normal mb-1 lg:mb-2 truncate ">{items?.t_genres_display}</p>
                                   </div>}
                                   {(isMusicAnalyzer) &&<div>
                                       <p className="text-[#312522] px-2 bg-[#FFE5E5] dark:bg-[#1A1A1A] dark:text-[#DDD1CE] text-center w-2/4 mx-auto rounded-[30px] py-1 text-xs font-normal mb-1 lg:mb-2 truncate ">File name</p>
                                   </div>}
                               </td>
                               {!isuploadPage &&<td className="!text-left w-[24%]  hidden lg:table-cell">
                                   <div className="flex justify-center gap-x-1 items-center">
                                       <HeadphonesIcon className='!w-4 lg:w-auto dark:!text-[#DDD1CE]'/>
                                       {/* <p className="text-[#312522] text-sm font-light">
                                       {!listenCount?.i_play_count ? items?.play_count : listenCount?.song_id===items?.id?listenCount?.i_play_count:items?.play_count}
                                       </p> */}
                                       {/* {console.log("sdasda",items?.id, countTracks?.find(item => item.id === items?.id)?.play_count || 0)} */}
                                       <p className="text-[#312522] text-sm font-light dark:text-[#DDD1CE]">
                                       {/* {items?.id===countTracks?.id && countTracks?.play_count} */}
                                       {/* {countTracks.find(item => item.id === items?.id?item?.play_count:0) } */}
                                       {countTracks?.find(item => item.id === items?.id)?.play_count || 0}
                                       
                                       </p>
                                   </div>
                               </td>}
                               <td className="!text-left w-[24%]  hidden lg:table-cell">
                                   <div className="flex justify-center gap-x-1 items-center">
                                       <AccessTimeOutlinedIcon className='!w-4 lg:w-auto dark:text-[#DDD1CE]'/>
                                       <p className="text-[#312522] text-sm font-light dark:text-[#DDD1CE]">{items?.i_duration_display || items?.i_duration}</p>
                                   </div>
                               </td>
                               {!create &&<td className="!text-left relative ">
                                   <div className="flex justify-end pr-2 lg:pr-0 lg:justify-center items-center gap-x-2">
                                        {isMusicAnalyzer && <div onClick={()=>setStep(6)}>
                                        <AudioFileOutlinedIcon className="hover:text-[#F1B942] cursor-pointer dark:text-[#DDD1CE]"/>
                                        </div>}
                                        {scope!=="globalSearch" &&<div  className={`${isuploadPage?"hidden":""}`}>

                                        {( !likedTracks?.includes(items.id)) ? 
                                            <FavoriteBorderIcon
                                                className="hover:text-[#F1B942] cursor-pointer dark:text-[#DDD1CE]"
                                                onClick={() => handleLike(items.id)}
                                            />
                                        : 
                                            <FavoriteIcon
                                                className="text-[#F1B942] cursor-pointer dark:text-[#DDD1CE]"
                                                onClick={() => handleLike(items.id)}
                                            />
                                        }
                                        </div>}
                                        <div className="hidden lg:block">
                                        <PlayCircleFilledWhiteIcon onClick={()=> playSong(items,index,items?.id)} className="cursor-pointer dark:!text-[#DDD1CE] "/>
                                        </div>

                                        {isuploadPage && <p onClick={()=>handleClickOpenDeleteTrackModal(items?.id)}  className="my-2 cursor-pointer hover:text-[#F1B942]"><DeleteOutlinedIcon className="mx-2 dark:!text-[#DDD1CE]"/></p>}

                                      {/* { (isPlayer===-1 || isPlayer!==index)&&<PlayArrowIcon onClick={()=>handlePlay(index,items?.id,items?.t_title)} className="hover:text-[#F1B942] cursor-pointer !hidden lg:!block"/>} */}
                                     {/* { isPlayer===index &&<PauseIcon onClick={()=>handlePlay(index,items?.id,items?.t_title)} className="hover:text-[#F1B942] cursor-pointer !hidden lg:!block"/>} */}
                            

                                       {scope!=="globalSearch"  && <div className={`${isuploadPage?"hidden":""}`}> <MoreVertOutlinedIcon  className={`hover:text-[#F1B942] threeDots-icon cursor-pointer dark:text-[#DDD1CE]  `}onClick={() => toggleDropdown(items.id)}/> </div>}

                                      { scope==="globalSearch" && <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleClickOpen(items.id)}><ShareOutlinedIcon className="mx-2 dark:text-[#DDD1CE]"/></p>}

                                        { scope==="globalSearch" &&<div className="lg:relative">
                                        <p onClick={()=>{ getPlaylistData(items?.id,items?.e_platform);setGlobalAddPlaylistModal(items.id);setCreatePlaylistModal(true)}} className="my-2 cursor-pointer hover:text-[#F1B942]"><QueueMusicOutlinedIcon  className="mx-2 dark:text-[#DDD1CE]"/></p>
                                        
                                        {(globalAddPlaylistModal === items.id  ) && <div ref={modalRef} className="hidden lg:block lg:absolute top-0 right-0 lg:top-[10px] lg:right-[40px] z-[99999] bg-white  lg:w-[200px] lg:h-[175px] w-full h-dvh  rounded-[4px]  border-[2px] border-[#A8A8A8] overflow-scroll dark:border-[1px] dark:border-[#343434] dark:bg-[#151515] dark:text-[#DDD1CE]">
                                        <div className="lg:hidden block">
                                            <div className="relative ">
                                                <ArrowBackIcon onClick={()=>setCreatePlaylistModal(false)} className="absolute  top-1/2  transform -translate-y-1/2"/>
                                                <p className="text-center">Add to playlist</p>
                                            </div>
                                            <div>
                                                <Button2  className="!w-[150px] !text-center !mx-auto">New playlist</Button2>
                                            </div>
                                        </div>
                                      <div className="relative p-2  w-[90%] lg:w-auto">
                                           <input
                                               type="text"
                                               placeholder="Search Playlist"
                                               className="bg-[#FAFAF8] rounded-[50px] px-2 py-1 w-full  focus:outline-none focus:border-[#F1B942] text-[#C1C1C1] lg:text-sm text-base font-normal  focus:border-[1px] transition duration-300 dark:bg-[#131313] dark:text-[#A1A1A1] "
                                               // value={searchQuery}
                                               onChange={handleSearchChange}
                                               // onChange={(e) => setSearchQuery(e.target.value)}
                                           />
                                           <SearchOutlinedIcon className="absolute !text-[#312522] !lg:text-base !text-lg top-1/2 transform -translate-y-1/2 right-3 dark:!text-[#A1A1A1]"/>
                                       </div>
                                       <div onClick={()=>handleClickOpenCreatePlaylistTrackModal(items?.id)} className="cursor-pointer p-2 text-[#312522] hidden lg:block font-semibold text-sm py-2 px-2 dark:text-[#DDD1CE]"><AddIcon/> Generate playlist </div>
                                      
                                       <div className="h-[80%] overflow-scroll">
                                       {filteredPlaylists?.map((playlist,index)=>{
                                        return(
                                            <div key={index}  className={` hidden lg:block py-2 px-2 cursor-pointer ${index===0?"lg:border-t-[1px] ":""} lg:border-b-[1px] text-xs font-normal lg:border-t-[#A8A8A8] lg:border-b-[#A8A8A8]`}>
                                                <p onClick={()=>{handleAddToPlaylist(playlist?.v_code);setGlobalAddPlaylistModal(false)}} className="hidden lg:block">{playlist?.v_name}</p>
                                            </div>
                                        )
                                       })}
                                        </div>
                                       </div>}
                                        </div>}
                                   </div>

                                  {openDropdownId === items.id && <div className="relative"> <div   className={` threeDots-modal absolute bottom-[85%] right-[25%] z-40 min-w-[200px] text-sm bg-white rounded-lg drop-shadow-lg p-3 dark:border-[1px] dark:border-[#343434] dark:bg-[#151515] dark:text-[#DDD1CE] `}>
                                   <div className="relative">
                                       <p className="my-2 cursor-pointer hover:text-[#F1B942]" onClick={()=>handleClickOpen(items.id)}><ShareOutlinedIcon className="mx-2"/>Share</p>

                                       {items?.e_platform==="Default" && scope!=="globalSearch" &&<p onClick={()=>handleClickOpenEditTrackModal(items)} className="my-2 cursor-pointer hover:text-[#F1B942]"><EditIcon className="mx-2"/>Edit</p>}

                                       { (scope!=="globalSearch" && !globalId) &&<p onClick={()=>handleClickOpenDeleteTrackModal(items?.id)}  className="my-2 cursor-pointer hover:text-[#F1B942]"><DeleteOutlinedIcon className="mx-2"/>Remove</p>}

                                       <p onClick={()=>{setCreatePlaylistModal(pre=>!pre); getPlaylistData(items?.id,items?.e_platform)}} className="my-2 cursor-pointer hover:text-[#F1B942]"><QueueMusicOutlinedIcon  className="mx-2"/>Add to playlist <ArrowRightOutlinedIcon/></p>

                                      {createPlaylistModal && <div className="fixed lg:absolute top-0 right-0 lg:top-[-21px] lg:right-[200px] z-[99999] bg-white  lg:w-[200px] lg:h-[175px] w-full h-dvh  rounded-[4px] dark:border-[1px] dark:border-[#343434]  overflow-scroll  dark:bg-[#151515] dark:text-[#DDD1CE]">
                                        <div className="lg:hidden block">
                                            <div className="relative ">
                                                <ArrowBackIcon onClick={()=>setCreatePlaylistModal(false)} className="absolute  top-1/2  transform -translate-y-1/2"/>
                                                <p className="text-center">Add to playlist</p>
                                            </div>
                                            <div>
                                                <Button2  className="!w-[150px] !text-center !mx-auto">New playlist</Button2>
                                            </div>
                                        </div>
                                      <div className="relative p-2  w-[90%] lg:w-auto">
                                           <input
                                               type="text"
                                               placeholder="Search Playlist"
                                               className="bg-[#FAFAF8] rounded-[50px] px-2 py-1 w-full  focus:outline-none focus:border-[#F1B942] text-[#C1C1C1] lg:text-sm text-base font-normal  focus:border-[1px] transition duration-300 dark:bg-[#131313] dark:text-[#A1A1A1] "
                                               // value={searchQuery}
                                               onChange={handleSearchChange}
                                               // onChange={(e) => setSearchQuery(e.target.value)}
                                           />
                                           <SearchOutlinedIcon className="absolute !text-[#312522] !lg:text-base !text-lg top-1/2 transform -translate-y-1/2 right-3 dark:!text-[#A1A1A1]"/>
                                       </div>
                                       <div onClick={()=>handleClickOpenCreatePlaylistTrackModal(items?.id)} className="cursor-pointer p-2 text-[#312522] hidden lg:block font-semibold text-sm py-2 px-2 dark:text-[#DDD1CE]"> <AddIcon/> Generate playlist </div>
                                      
                                       <div className="h-[80%] overflow-scroll">
                                       {filteredPlaylists?.map((playlist,index)=>{
                                        return( 
                                            // <div key={index}  className={` hidden lg:block py-2 px-2 cursor-pointer ${index===0?"lg:border-t-[1px] ":""} lg:border-b-[1px] text-sm font-normal lg:border-t-[#A8A8A8] lg:border-b-[#A8A8A8]`}>
                                            //     <p onClick={()=>handleAddToPlaylist(playlist?.v_code)} className="hidden lg:block">{playlist?.v_name}</p>
                                            // </div>
                                            <div key={index}  className={` hidden lg:block py-2 px-2 cursor-pointer ${index===0?" ":""}  text-sm font-normal `}>
                                            <p onClick={()=>handleAddToPlaylist(playlist?.v_code)} className="hidden lg:block">{playlist?.v_name}</p>
                                        </div>
                                        )
                                       })}

                                        </div>
                                       </div>}
                                   </div>
                                   </div>
                                   </div>}
                               </td>}
                           </tr>
                       )
                   })
                
                 : 
                //  <div className="w-full absolute text-center bg-white drop-shadow-xl py-2">No tracks available</div>

                <div className={`w-full absolute text-center  bg-white dark:bg-[#151515] dark:text-white py-2`}>{type==="all"?"No tracks available":type==="liked"?"No Liked tracks available":"No Shared Tracks available"}</div>
                 }
            </tbody>
            </table>
            </div>
            </div>
        </div>
    )
}

export default Tracks