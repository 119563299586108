import { Link, useLocation } from "react-router-dom";
import Pills from "./Pills"
import ShareModal from "./ShareModal";
import { useEffect, useRef, useState } from "react";
import { handleOpenModal } from "../../../store/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { handleShareModal } from "../../../store/shareModalGlobalSlice";
import { playerOn } from "../../../store/trackSlice";
import { useApi } from "../../../api/api";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Dialog } from 'primereact/dialog';
import CheckIcon from '@mui/icons-material/Check';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import DialogBox from "./DialogBox";
import { handleCurrentSongIndex, handleIsRecommended, handlePlayerStatus } from "../../../store/playerSlice";
import EditPlaylist from "../common/TracksComponents/EditPlaylist";

function DetailTopSection({image,name,time,decades,detailtitles,details,tracksLength,title,Id,handleRowSelect ,isSelected,handleClickEditPlaylistTrackModal,platform,vCode,AlbumCount,ArtistCount,RegionCount,platformName,t_playlist_id,Like,selectedRows,manageId,handlelike,managePlaylistData,setManagePlaylistData,t_description="",is_synced,refreshPlaylist,is_global,type,setPlaylistDetailData}){
    const trackData = useSelector((state) => state.tracks.trackData);
    const[openEditPlaylistModal,setOpenEditPlaylistModal] = useState("")
    const [visible, setVisible] = useState(false);
    const location = useLocation()
    const dispatch = useDispatch()
    const { handleGetRequest,handlePostRequest } = useApi();
    const [open, setOpen] = useState(false);
    const [like, setLike] = useState(Like);
    const [id, setId] = useState("");
    const queryParams = new URLSearchParams(location.search);
    const idd = queryParams.get('id');
    console.log("xzcxzcds",idd);
    const play = queryParams.get('play');

    console.log("play",play);

    const [lastPathSegment, setLastPathSegment] = useState('');
    const [lastPathPlatform, setLastPathPlatform] = useState('');

    useEffect(()=>{
        if(play){
            dispatch(playerOn(0))
        }
    },[])

    // Step 3: Use the useEffect hook to update the state when the component mounts
    useEffect(() => {
       // Get the current pathname
       const pathname = location.pathname;
     
   
       // Split the pathname into segments
       const segments = pathname.split('/');
   
       // Get the last segment
       const lastSegment = segments[segments.length - 1];
       const secondLastSegment = segments[segments.length - 2];
       const modifiedData = secondLastSegment.slice(0, -1);
   
       // Step 4: Update the state with the last segment
       setLastPathSegment(lastSegment);
       setLastPathPlatform(modifiedData)
    }, []);

   
    const handleLikeTopSection = async() => {
        const playlistRes = await handleGetRequest(`${`${lastPathPlatform}-favourite?id=${idd}&type=${like?"Like":"DisLike"}`}`,true,false)
    }
    const isFirstRender = useRef(true)
    useEffect(()=>{
        if (isFirstRender.current) {
            // If it's the first render, set the ref to false and return early
            isFirstRender.current = false;
            return;
          }
      
          // If it's not the first render, execute your effect
          handleLikeTopSection();
    },[like])
  
    const[reSyncCount,setReSyncCount] = useState(false)

    const resyncPlaylist = async() => {
        console.log("platform",platform, platformName);
        console.log("t_playlist_id",t_playlist_id);
        const reSyncRes = await handleGetRequest(`${platform==="Youtube Video"?"youtube-video":platform==="Youtube Music"?"youtube-music":platform?.toLowerCase()}/sync/playlist/${t_playlist_id}`,true,true)
        console.log("reSyncRes",reSyncRes?.data?.songCount)
        setReSyncCount(reSyncRes?.data?.songCount)
        refreshPlaylist()
    }

    console.log("reSyncCount",manageId)
    
    

    const handleShareModalGlobal = (id) => {
        if (typeof localStorage !== 'undefined') {
            localStorage.setItem('itemId', title==="manage-playlist"?manageId:id)
        }

        (title==="manage-playlist" || title==="Playlists")  ? dispatch(handleShareModal(`https://curioushit-frontend.nevinainfotech.in/collection/playlists/${title==="Playlists"?Id:vCode}`)): dispatch(handleShareModal(`https://curioushit-frontend.nevinainfotech.in/collection/${title.toLowerCase()}/${Id}`))

        const newUrl = `${location.pathname}?${Date.now()}`;
        window.history.pushState({}, '', newUrl);
    };


    useEffect(() => {
        const handlePopState = (event) => {
       //    callback(event);
        //   setNotificationPopUpMobile(false)
        dispatch(handleShareModal())
          
        };
    
        window.addEventListener('popstate', handlePopState);
    
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
      }, [])

      
    const handleClose = () => {
        setOpen(false);
    };

    const handlePlayNow = () => {
        dispatch(handlePlayerStatus(false))
        //execute after 2 sec
        setTimeout(() => {
            dispatch(handlePlayerStatus(true));
            dispatch(handleIsRecommended(false))
            localStorage.setItem('playingCurrentSong',  JSON.stringify(trackData[0]))
            dispatch(handlePlayerStatus(trackData[0]?.e_platform));
            dispatch(handleCurrentSongIndex(0))
        }, 100);
        // dispatch(playerOn(0))
    }

   


    //   useEffect(() => {
    //     if (details && details.data && details.data.v_code) {
    //       const params = new URLSearchParams(location.search);
    //       if (params.get('play') === 'true') {
    //         // Your code here
    //         dispatch(handlePlayerStatus(false))
    //         //execute after 2 sec
    //         setTimeout(() => {
    //             dispatch(handlePlayerStatus(true));
    //             dispatch(handleIsRecommended(false))
    //             localStorage.setItem('playingCurrentSong',  JSON.stringify(trackData[0]))
    //             dispatch(handlePlayerStatus(trackData[0]?.e_platform));
    //             dispatch(handleCurrentSongIndex(0))
    //         }, 100);
    //       }
    //     }
    //   }, [location, details]);

    const [oldData,setOldData] = useState({
        "playlistImage": '', // URL or File for prefilled image if available
        "playlistTitle":name,
        "playlistDescription": ""
      })

      console.log("oldData",name);

      const [playlistName,setPlaylistName] = useState()

    function getVNameById(id) {
     const playlistItem = managePlaylistData?.find(item => item.data.id === id);
     return playlistItem ? playlistItem.data.v_name : null;
    }

    useEffect(()=>{
    const vName = getVNameById(openEditPlaylistModal)
    console.log("vName",vName);
    setPlaylistName(vName)
    },[openEditPlaylistModal])

    // const [isplayedTrue, setIsPlayedTrue] = useState(false)
    // useEffect(() => {
    //     const isplaying = localStorage.getItem("playingCurrentSong")
    //     console.log("isplaying",isplaying);
        
    //     const params = new URLSearchParams(location.search);
    //     if (params.get('play') === 'true' && (type==="all" ) ) {
    //         console.log("checktrack",trackData);
    //      dispatch(handlePlayerStatus(false))
    //         //execute after 2 sec
    //         setTimeout(() => {
    //             dispatch(handlePlayerStatus(trackData?true:false));
    //             dispatch(handleIsRecommended(false))
    //             localStorage.setItem('playingCurrentSong',  JSON.stringify(trackData?.length>0&&trackData[0]))
    //             dispatch(handlePlayerStatus(trackData?.length>0?trackData[0]?.e_platform:false));
    //             dispatch(handleCurrentSongIndex(trackData?.length>0?0:-1))
    //             // setIsPlayedTrue(true)
    //         }, 200);
        
    //     }
    //   }, [location]);

      console.log("oreotitle",title,is_synced,(title==="Playlists" && is_synced) , (title==="manage-playlist" && is_synced));
    
    return(
        <div className={`bg-white w-full rounded-xl ${title==="manage-playlist"?"":"drop-shadow-xl"} flex justify-between items-center dark:bg-[#151515]`}>

        {((platform==="Default" && playlistName) || !is_synced) &&  <EditPlaylist openEditPlaylistModal={openEditPlaylistModal} setOpenEditPlaylistModal={setOpenEditPlaylistModal} name={platform==="Default"?name:""} managePlaylistData={managePlaylistData} oldData={oldData} setOldData={setOldData} playlistName={playlistName} setPlaylistName={setPlaylistName} image={image} setManagePlaylistData={setManagePlaylistData} t_description={platform==="Default"?t_description:""} setPlaylistDetailData={setPlaylistDetailData} title={title}/>}

        {title==="manage-playlist"&&<div className="flex justify-center items-center pl-3">
       
        <input 
            type="checkbox" 
            onChange={() => handleRowSelect(Id)} 
            checked={selectedRows.includes(Id)} 
            className=" accent-[#312522]  w-7 h-7 cursor-pointer"
          />
        </div>}
        <div className=" lg:py-5 py-3 w-full  p-3 gap-x-7 relative lg:grid lg:grid-cols-12">
            <ShareModal  id={id} location={location}/>
            <div className="absolute top-0 border-t-transparent right-[4%] border-[2px] flex justify-center items-center text-center  h-9  rounded-b-3xl border-[#F1B942] p-2">
                <div className="text-[8px] text-[#F1B942]">
                <p>{reSyncCount || tracksLength}</p>
                <p>Tracks</p> 
                </div>
            </div>
            <div className={`col-span-4 mx-auto  lg:mx-0 w-[60%] h-40 lg:!w-60 xl:!w-80 lg:h-80 ${title==="Decades"?"rounded-xl flex justify-center items-center":""} ${title==="manage-playlist"?"w-52 h-40 lg:!w-60 xl:!w-80 lg:!h-80":""} ${title==="Geos"?"flex flex-col justify-center items-center":""}`} style={{backgroundColor:details?.v_color_code}}>
              { !image && <p className="text-white text-5xl lg:text-9xl h-64 lg:h-auto w-full flex justify-center items-center dark:text-[#DDD1CE]"><p>{details?.v_year}</p></p>}

                {title==="manage-playlist"?
                <div className="relative  w-full !h-full">
                <img src={image?image:""} alt="" className={`object-cover ${title==="manage-playlist"?"w-full":""}  rounded-xl w-full !h-full  ${title==="Geos"?"!w-[70%] !h-[70%] !object-contain":""} ${title==="Decades"?"!hidden":""}`}/>
                <Link to={`/collection/playlists/${details?.data?.v_code}?id=${details?.data?.id}&globalid=undefined`} className="bg-black bg-opacity-40 absolute top-0 w-full h-full rounded-lg"></Link>
                {/* {details?.data?.is_liked &&<FavoriteBorderOutlinedIcon className="absolute top-3 left-3 !text-white"/>} */}
                {details?.data?.i_share_count>0 &&<AccountCircleOutlinedIcon  className="absolute top-3 left-10 !text-white"/>}
                </div>
                :
                <img src={image?image:""} alt="" className={`object-cover ${title==="manage-playlist"?"w-full":""}  rounded-xl w-full !h-full  ${title==="Geos"?"!w-[70%] !h-[70%] !object-contain":""} ${title==="Decades"?"!hidden":""}`}/>}
            </div>

            <div className="col-span-8 flex flex-col h-full lg:h-80">
                <h3 className="text-[#312522] text-center lg:!text-left text-lg lg:text-xl font-semibold my-4 dark:text-[#DDD1CE]">{name}</h3>

                <div className="flex justify-center gap-y-3 flex-wrap lg:flex-nowrap lg:justify-start items-center gap-x-2 ">
               { title==="manage-playlist"?<div className="flex justify-center gap-y-3 flex-wrap lg:flex-nowrap lg:justify-start items-center gap-x-2 ">
                {details?.data?.dataCounts?.map((items,index)=>{
                    return(
                        <Pills className="bg-[#FFEBD4] !mx-2">{items}</Pills>
                    )
                })}
                </div>: <div className="flex justify-center gap-y-3 flex-wrap lg:flex-nowrap lg:justify-start items-center gap-x-2 ">
                {details?.dataCounts?.map((items,index)=>{
                    return(
                        <Pills className="bg-[#FFEBD4] !mx-2">{items}</Pills>
                    )
                })}
                    </div>}
                </div>

                {title==="manage-playlist"&&<div className="lg:grid grid-cols-4 gap-x-2 my-3 hidden ">
                    {details?.data?.Top && Object.entries(details?.data?.Top)?.map(([key, value]) => (
                        <div key={key} className={`my-3 ${value?.length > 0 ?"":"hidden"}`}>
                        {value?.length > 0 &&  <h2 className="text-[#A1A1A1]  text-sm font-normal my-3">{key}</h2>}
                        {value?.length > 0 && (
                            <ol className="">
                            {value?.map((item, index) => (
                                // <li className="text-xs list-decimal my-1 text-[#A1A1A1] line-clamp-1 font-light" key={index}>{index+1}. {item}</li>
                                <li className={`text-xs ${key==="artists"?"font-semibold !text-sm":""} list-decimal  line-clamp-1  text-[#A1A1A1] font-light ${key==="Genres"?"bg-white  text-center py-1 rounded-2xl  !text-[#3871E4] border-[1px] border-[#3871E4] my-1":""}`} key={index}>{key==="Genres"?"":index+1}{key==="Genres"?"":"."}  {item}</li>
                            ))}
                            </ol>
                        )}
                        </div>
                    ))}
                </div>}
                {title!=="manage-playlist"&&<div className={`grid ${
                     Object?.entries(details?.Top || {})?.length === 3 
                     ? "grid-cols-3" 
                     : Object?.entries(details?.Top || {})?.length === 4 
                     ? "grid-cols-4" 
                     : Object?.entries(details?.Top || {})?.length === 2 
                     ? "grid-cols-2" 
                     : "grid-cols-1"
                }  gap-x-2 my-3 mx-10 lg:mx-0 lg:grid-cols-4`}>
                {details?.Top && Object.entries(details?.Top)?.map(([key, value]) => (
                        <div key={key} className={`my-3 ${value?.length > 0 ?"":"hidden"}`}>
                        {value?.length > 0 &&  <h2 className={`text-[#A1A1A1] capitalize ${key==="Genres"?"text-center":""} text-sm font-normal my-3 `}>{key}</h2>}
                        {value?.length > 0 && (
                            <ol className="">
                            {value?.map((item, index) => (
                                <li className={`text-xs ${key==="artists"?"font-semibold !text-sm":""} list-decimal  line-clamp-1  text-[#A1A1A1] font-light ${key==="Genres"?"bg-white  text-center py-1 rounded-2xl  !text-[#3871E4] border-[1px] border-[#3871E4] my-1":""}`} key={index}>{key==="Genres"?"":index+1}{key==="Genres"?"":"."}  {item}</li>
                            ))}
                            </ol>
                        )}
                        </div>
                    ))}
                </div>}
                <div className="bg-[#312522] dark:bg-[#DDD1CE] flex lg:justify-end justify-center items-center lg:!mt-auto mt-5 h-[75px] lg:h-[90px] rounded-lg">
                        <div className="flex lg:justify-end justify-center  items-center gap-x-3 px-3">
                           { title!=="manage-playlist"?<div>
                                <button className="max-w-[150px] bg-white px-3 py-2 rounded-3xl flex justify-between items-center gap-x-2 text-[#F1B942] dark:bg-[#0D0D0D] dark:text-[#F3C25A]" onClick={()=> handlePlayNow() }>
                                    <span><img src="/Group333.png" alt="play circle" className=""/></span><span>Play <span className="hidden lg:inline-block">now</span> </span>
                                </button>
                            </div>:<Link  to={`/collection/playlists/${details?.data?.v_code}?play=true&id=${details?.data?.id}&globalid=undefined`} className="max-w-[150px] bg-white px-3 py-2 rounded-3xl flex justify-between items-center gap-x-2 text-[#F1B942] dark:bg-[#0D0D0D] dark:text-[#F3C25A]"> <span><img src="/Group333.png" alt="play circle" className=""/></span><span>Play  <span className="hidden lg:inline-block">now</span></span></Link>}

                            {/* {title!=="manage-playlist" && !Like ? <FavoriteBorderOutlinedIcon onClick={()=>setLike(pre=>!pre)} className={`!text-white hover:!text-[#F1B942] p-2 !w-12 !h-12 lg:!w-12 lg:!h-12 rounded-full  border-[2px] !border-white hover:!border-[#F1B942] cursor-pointer `}/>:<FavoriteIcon onClick={()=>setLike(pre=>!pre)} className={`!text-[#F1B942] hover:!text-[#F1B942] p-2 !w-12 !h-12 lg:!w-12 lg:!h-12 rounded-full  border-[2px] !border-[#F1B942] hover:!border-[#F1B942] cursor-pointer `}/>} */}

                           {title!=="manage-playlist" && <div onClick={()=>setLike(pre=>!pre)} className={`border-[2px] ${like?'border-[#F1B942] dark:border-[#0D0D0D]':'border-white dark:!border-[#0D0D0D]'}  p-2 rounded-full`}>
                            {like?<FavoriteIcon className="!text-[#F1B942] cursor-pointer dark:!text-[#0D0D0D]"/>: <FavoriteBorderOutlinedIcon className="!text-white cursor-pointer dark:!text-[#0D0D0D]"/>}
                            </div>}
                        {title==="manage-playlist" && <div onClick={()=>{handlelike(Id)}} className={`border-[2px] ${details?.data?.is_liked?'border-[#F1B942]':'border-white dark:!border-[#0D0D0D]'}  p-2 rounded-full`}>
                        {details?.data?.is_liked?<FavoriteIcon className="!text-[#F1B942] cursor-pointer dark:!text-[#0D0D0D]"/>: <FavoriteBorderOutlinedIcon className="!text-white cursor-pointer dark:!text-[#0D0D0D]"/>}
                        </div>}

                           
               
                            <ShareOutlinedIcon className="!text-white hover:!text-[#F1B942] p-2 !w-12 !h-12 lg:!w-12 lg:!h-12 rounded-full  border-[2px] !border-white hover:!border-[#F1B942] cursor-pointer dark:!text-[#0D0D0D] dark:!border-[#0D0D0D]" onClick={()=>handleShareModalGlobal(Id)}/>
                      
                         
                            {((title==="Playlists" && !is_synced && !is_global) || (title==="manage-playlist" && !is_synced  && !is_global)) &&
                                <EditOutlinedIcon className="!text-white hover:!text-[#F1B942] !p-2  !w-12 !h-12 rounded-full  border-[2px] !border-white hover:!border-[#F1B942] cursor-pointer dark:!text-[#0D0D0D] dark:!border-[#0D0D0D]" onClick={() => setOpenEditPlaylistModal(Id)} />
                       
                            }

                          

                            {/* {(title==="manage-playlist" && platform!=="Default") &&
                                <SyncOutlinedIcon onClick={()=>resyncPlaylist()} className="!text-white hover:!text-[#F1B942] p-2 !w-12 !h-12 lg:!w-12 lg:!h-12 rounded-full  border-[2px] !border-white hover:!border-[#F1B942] cursor-pointer dark:!text-[#0D0D0D] dark:!border-[#0D0D0D] "/>
                       
                            } */}
                             {((title==="Playlists" && is_synced && !is_global) || (title==="manage-playlist" && is_synced && !is_global))&&
                                <SyncOutlinedIcon onClick={()=>resyncPlaylist()} className="!text-white hover:!text-[#F1B942] p-2 !w-12 !h-12 lg:!w-12 lg:!h-12 rounded-full  border-[2px] !border-white hover:!border-[#F1B942] cursor-pointer dark:!text-[#0D0D0D] dark:!border-[#0D0D0D] "/>
                       
                            }
                        </div>
                    </div>
            </div>
        </div>
        </div>
    )
}

export default DetailTopSection